import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import CategoryModal from "../../popupModals/CategoryModal";
import usePutDataServices from "../../hooks/usePutDataServices";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import Loader from "../common/Loader";

const CategoryScreens = () => {
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [categoryModal, setCategoryModal] = useState({
    visibility: false,
    type: "",
    id: "",
  });

  const handleAddEditData = (type, id) => {
    setCategoryModal({
      ...categoryModal,
      visibility: true,
      type,
      id,
    });
  };

  const handlePublish = (id) =>{
    UpdateApiServices.publishCategory(id).then((res)=>{
      SuccessToast("Category Published Successfully");
      fetchCategoryData();
    }).catch((err)=>{
      fetchCategoryData();
      ErrorToast('Something Went Wrong');
    });
  };

  const fetchCategoryData =()=>{
    fetchData(
      GetApiServices.getAllCategories(),
      setCategories,
      setLoader );
  }

  const handleStatus =(data)=>{
    let status = (data.isActive == true) ? false : true ;
    UpdateApiServices.updateCategoryStatus(data.id,status).then((res)=>{
      fetchCategoryData();
    }).catch((err)=>{
      fetchCategoryData();
      ErrorToast('Something Went Wrong');
    });
  }

  useEffect(() => {
    fetchCategoryData();
  }, [categoryModal]);



  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">Categories</h4>
          </div>
          <div className="col-auto pe-1">
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")} >
              Add Category
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 col-md-12 table-responsive fixed-table"
            style={{ overflowX: "auto" }}
          >
            <table class="table table-fit  align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  {/* <th scope="col">Sr. no</th> */}
                  <th scope="col" className=" col-3">Name</th>
                  <th scope="col" className="text-center">Status</th>
                  <th scope="col" className="text-center">Publish</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody className="mid_height">
              {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="9" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : categories.length ? (
                categories.map((curVal, index) => (
                  <tr key={index}>
                    <td className="col-3">{curVal.name}</td>
                    <td className="text-center">
                    <div className="form-switch">
                      <input className="form-check-input custom-control-input" type="checkbox" role="switch" disabled={!curVal.isPublish}
                      checked={curVal.isActive} onChange={()=>handleStatus(curVal)}/>
                    </div>
                    </td>
                    <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={curVal.isPublish}
                            checked={curVal.isPublish} onClick={()=>handlePublish(curVal.id)} />
                          </div>
                        </td>
                    <td className="text-center">
                      <button className="btn btn-outline-primary" 
                      onClick={() => handleAddEditData("Edit_Data", `${curVal.id}`) } disabled={curVal.isPublish}>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                      </button>
                    </td>
                  </tr>
                ))
                ) : (
                  <>
                    <tr className=" my-5 text-center">
                    <th colSpan={5} className="py-5">
                      <h4 className="text-muted">No Data Available</h4>
                    </th>
                    </tr>
                  </>)}
              </tbody>
            </table>
          </div>
        </div>
        {categoryModal.visibility ? (
          <CategoryModal
            categoryModal={categoryModal}
            setCategoryModal={setCategoryModal}
            type={categoryModal.type}
            id={categoryModal.id}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CategoryScreens;
