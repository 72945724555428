import axios from "axios";

const useAxios = () => {
  const token = JSON.parse(localStorage.getItem('data'));
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

  const axiosCreate = axios.create({
    baseURL: BASE_URL,
    headers:{
      "Authorization":`Bearer ${token}`
    }
  })
  
  return (
    axiosCreate
  )
}

export default useAxios