import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast,InformationToast } from "../utilities/notification";
import usePutDataServices from "../hooks/usePutDataServices";
import useGetDataServices from "../hooks/useGetData";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import Loader from "../components/common/Loader";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const OnboardingScreenModal = (props) => {
  const modelRef = useRef();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [loader, setLoader] = useState(false);
  const [refreshImagePreview, setRefreshImagePreview] = useState(false);
  const [onbordingScreens, setOnbordingScreens] = useState({
    screenType: "",
    title: "",
    buttonText: "",
    imageUrl: "",
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();
  const [screenType, setScreenType]= useState([
  "EMAIL_PAGE",
  "ACCESS_CODE_PAGE",
  "ZIP_CODE_PAGE", 
  "NOT_IN_COMMUNITY_PAGE", 
  "NAME_PAGE", 
  "PASSWORD_PAGE", 
  "MOBILE_NUMBER_PAGE", 
  "WALLET_NAME_PAGE", 
  "FORGOT_PASSWORD_PAGE",
  "NEW_PASSWORD_PAGE"
  ]);
  

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const cleanedValue = name === 'screenType' ? value : value.trimStart().replace(/[^a-zA-Z\s]/g, '').replace(/\s{2,}/g, ' ');
    setOnbordingScreens({ ...onbordingScreens, [name]:cleanedValue});
    removeValidations();
  };

  const formValidations = () => {
      for (const key of Object.keys(onbordingScreens)) {
        if (onbordingScreens[key] === "") {
          invalidFields.push(key)
          setValidation(false);
          modelRef.current.scrollTop = 0;
        }
      }
      if (validation && !invalidFields.length) {
        handleAddEditScreen();
      }  
  }

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };

  const handleAddEditScreen = () => {
    setLoader(true);
    const fileterData = ["screenType", "title", "buttonText", "imageUrl"];
    for (let key of Object.keys(onbordingScreens)) {
      if (!fileterData.includes(key)) {
        delete onbordingScreens[key];
      }
    }
    if (props.id) {
      UpdateApiServices.updateOnboardingScreens(onbordingScreens, props.id)
        .then(async (res) => {
          if (res.status === 200) {
            SuccessToast("Data updated successfully");
            await props.setOnboardingSetupModal(true);
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{ErrorToast("Something went wrong  !!!");}
        })
        .catch((error) => {
          error.response.data.message.map((curError,index)=> ErrorToast(curError))
          console.log("Error onboarding update", error);
        }).finally(()=>setLoader(false));
    } else {
      PostApiServices.AddOnboardingScreen(onbordingScreens)
        .then(async (res) => {
          if (res.status === 201) {
            SuccessToast("Data Added Successfully.");
            setLoader(false);
            await props.setOnboardingSetupModal(true);
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{ErrorToast("Something went wrong  !!!");}
        })
        .catch((error) => {
          error.response.data.message.map((curError, index) =>
            ErrorToast(curError)
          );
          console.log("Add onboardingScreen error : ", error);
          setLoader(false);
        }).finally(()=>setLoader(false));
    }
  };

  // ------For image preview (Alternate)------;
  // const onImageChange = (event) => {
  //   let reader = new FileReader();
  //   if (event.target.files && event.target.files.length > 0) {
  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onload = () => {
  //       setImagePreview(reader.result);
  //     };
  //   }
  // };

  useEffect(() => {
    if (props.id) {
      GetApiServices.getOnboardingScreenByID(props.id)
        .then((res) => setOnbordingScreens(res.data))
        .catch((error) => {
          ErrorToast("Something went wrong while fetching data !!!");
          console.log("Error onboarding update", error);
        });
    }

    //Purpose : Fetching and filtering the Screen Types' 
    GetApiServices.getAllOnboardingScreens()
    .then(async (res) => {
      if(res.status === 200){
      const allScreenTypes =await res.data.map((curVal) => curVal.screenType);
      const updatedScreenType = await screenType.filter((screen) => !allScreenTypes.includes(screen));
      setScreenType(props.id ? screenType : updatedScreenType);
    }
    })
    .catch((error) => console.log("Error fetching onboarding screens Types", error));
  }, []);

  return (
    <div>
      <Modal
        show={props.onboardingModal}
        onHide={() => props.setOnboardingSetupModal(true)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title>{props.type === "Add_Data" ? 'Add' :"Edit"} Onboarding Screen</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modelRef} className="modal-height">
        {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
            </div>
          )}
          <form>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Screen Name
              </label>
              <select
                className="form-select custom-select"
                name="screenType"
                value={onbordingScreens.screenType}
                onChange={handleInputs}
                disabled={props.id}
              >
                <option value="" disabled selected className="my-2 py-5">
                  select screen
                </option>
                {screenType.map((curVal)=>
                  <option value={curVal}>{curVal}</option>
                )}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={onbordingScreens.title}
                onChange={handleInputs}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Button Text
              </label>
              <input
                type="text"
                className="form-control"
                id="buttonText"
                name="buttonText"
                value={onbordingScreens.buttonText}
                onChange={(e) => { setOnbordingScreens({ ...onbordingScreens, buttonText: e.target.value}); removeValidations()}}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Screen Image
              </label>
              <input
                type="file"
                className="form-control"
                id="imageUrl"
                defaultValue={
                  onbordingScreens.imageUrl === ""
                    ? ""
                    : onbordingScreens.imageUrl
                }
                accept="image/png, image/jpeg"
                name="imageUrl"
                onChange={(e) => {
                  imageServices.validateImageDimensions(
                    e.target.files[0],
                    setOnbordingScreens,
                    onbordingScreens,
                    setLoader
                  );
                  setRefreshImagePreview(true);
                  removeValidations()
                  // validateImageDimensions(e.target.files[0],setSetupScreenInputs,setupScreenInputs); uploadImageToserver(e.target.files[0]);
                }}
              />
              <p className="text-muted">
                Note : Image dimension should be 512 x 512
              </p>
            </div>
          </form>
          {onbordingScreens.imageUrl ? (
            <div>
              <p>Image Preview : </p>
              <div className="row ">
                {loader ? (
                  <div className="h-75 col-5 text-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <img
                      src={onbordingScreens.imageUrl}
                      className=" col-5 img-fluid border"
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="primary"
            onClick={formValidations}
            disabled={loader}
          >
            {loader ? <Loader /> : ""}
            {props.type === "Add_Data" ? "Add" : "Update"}
          </Button> */}
          <Button
          variant="primary"
          disabled={loader}
          onClick={formValidations}
        ><div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OnboardingScreenModal;
