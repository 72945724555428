import React, { useEffect, useState } from "react";
import AddWalletAdvertiseModal from "../../popupModals/AddWalletAdvertiseModal";
import useGetDataServices from "../../hooks/useGetData";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate,useParams } from "react-router";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";
import { Link } from "react-router-dom";

const AddWalletAdvertise = () => {
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const [allAdvertisements, setAllAdvertisements] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const params = useParams();

    
    const [addWalletAdvertiseModal, setAddWalletAdvertiseModal] = useState({
        visibility: false,
        type: "",
        id:""
    });

    const [deleteModal, setDeleteModal] = useState({
      visibility: false,
      deleteItem: "",
      id:""
    });

    const handleAddEditData = (type,id) =>{
        setAddWalletAdvertiseModal({
        ...addWalletAdvertiseModal,
        visibility: true,
        type,
        id
      });
    }

    const handlePublish = (id) =>{
      UpdateApiServices.publishAdvertisement(id).then((res)=>{
        SuccessToast("Advertisement Published Successfully");
        fetchAdvertisementsData();
      }).catch((err)=>{
        fetchAdvertisementsData();
        ErrorToast('Something Went Wrong');
      });
    };

    const handleStatus =(data)=>{
      let status = (data.isActive === true) ? false : true ;
      UpdateApiServices.updateAdvertisementStatus(data.id,status).then((res)=>{
        fetchAdvertisementsData();
      }).catch((err)=>{
        fetchAdvertisementsData();
        ErrorToast('Something Went Wrong');
      });
    }
  
    const fetchAdvertisementsData =()=>{
      const id = params.advertisementTypeId;
      GetApiServices.getAllAdvertisementsById(id).then((res)=>{
        (res.status === 200) && setAllAdvertisements(res.data)      
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
      .finally(()=>setLoader(false))
    }
   

    useEffect(()=>{
      fetchAdvertisementsData()
    },[addWalletAdvertiseModal])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col">
              <h4 className="text-primary fw-bold">Advertise Type: {params.advertisementTypeName}</h4>
            </div>
            <div className="col-auto">
                <button className="btn btn-outline-secondary col-auto me-3" onClick={() => navigate("/wallet")}>Back</button>
                <button
                className="btn btn-primary add-button col-auto"
                onClick={() => handleAddEditData("Add_Data")}
                >
                Add Advertise
                </button>
            </div>
            
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col-1" className="col-2">Title</th>
                    <th scope="col-3" className="col">Description</th>
                    <th scope="col-1" className="col">Logo</th>
                    <th scope="col-2" className="col-3">URL</th>
                    <th scope="col-1" className="text-center">Status</th>
                    <th scope="col-2" className="text-center">Publish</th>
                    <th scope="col-2" className="text-center"></th>
                  </tr>
                </thead>
                <tbody className="">
                {loader ? (
                    <tr className=" my-5 text-center">
                      {" "}
                      <th colSpan="9" className="py-5">
                        <Loader />
                      </th>
                    </tr>
                  ) : (
                    allAdvertisements.length ? (
                    allAdvertisements.map((item, index) => (
                      <tr key={index}>                      
                        <td className="col-2">{item.title}</td>
                        <td className="col-3">{item.description}</td>
                        <td className="col-1">
                          <Link to={item.imageUrl} target="_blank">
                            <img src={item.imageUrl} width="70" height="70" />
                          </Link>
                        </td>
                        <td className="col-3 text-break">
                            {item.url}                            
                          </td>
                        <td className="col-1 text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={!item.isPublish}
                              onChange={()=>handleStatus(item)}  
                              checked={item.isActive} />
                          </div>
                        </td>     
                        <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={item.isPublish}
                            checked={item.isPublish} onClick={()=>handlePublish(item.id)}/>
                          </div>
                        </td>                 
                        <td className="text-center">
                            <div className="btn-group">
                            <button type="button" className="dot"  data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul className="dropdown-menu py-0">
                            {item.isPublish ?
                              <div className="">
                                <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${item.id}`) } >
                                <i className="fa-solid fa-eye"></i> View
                                </li>
                              </div>
                              :
                              <div className="">
                                <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${item.id}`) } >
                                <i className="fa-solid fa-pen-to-square"></i> Edit
                                </li>
                              </div>}
                            </ul>
                          </div>
                          </td>
                      </tr>
                    ))
                    ) 
                    : (<>
                        <tr className=" my-5 text-center">
                      <th colSpan="9" className="py-5">
                        <h3>No Data Available</h3>
                      </th>
                    </tr>
                    </>)
                  )}
                   
                </tbody>
              </table>
            </div>
          </div>
          {addWalletAdvertiseModal.visibility ? (
            <AddWalletAdvertiseModal
            addWalletAdvertiseModal={addWalletAdvertiseModal}
            setAddWalletAdvertiseModal={setAddWalletAdvertiseModal}
            id={addWalletAdvertiseModal.id}
            advertisementTypeId={params.advertisementTypeId}
            type={addWalletAdvertiseModal.type}
            />
            ) : (
            ""
            )}
        </div>
        </>
      );
}

export default AddWalletAdvertise