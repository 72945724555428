import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import hamberger_icon from "../../assets/images/hamburger_icon.svg";
import toggle_icon from "../../assets/images/toggle_icon.png";
import notification_icon from "../../assets/images/notification_icon.svg";
import message_icon from "../../assets/images/message_icon.svg";
import avatar_profile from "../../assets/images/avatar.png";
import logo from "../../assets/images/sidebar_logo.svg";
import signout from "../../assets/images/signout.png";
import password_icon from "../../assets/images/password_icon.svg";
import Create_Account from "../../assets/images/Create_Account.png";
import AuthContext from '../../context/AuthProvider';
import { useNavigate } from 'react-router';
import CreateUserModal from '../../popupModals/CreateUserModal';
import ChangePasswordModal from '../../popupModals/ChangePasswordModal';


const Header = (props) => {
  const {setToggleSidebar, toggleSidebar} = props;
  const navigate = useNavigate();
  const [userData,setUserData]= useState();
  const [createUserModal,setCreateUserModal]= useState(false);
  const [changePasswordModal,setChangePasswordModal]= useState(false);
  const authContext = useContext(AuthContext);

  const logoutUser = (e) => {
    localStorage.clear();
    // authContext.setUserData([]);
    authContext.setLoggedIn(false)
    e.preventDefault();
    navigate("/")
  }

  useEffect(()=>{
    let user = localStorage.getItem('user');
    setUserData(JSON.parse(user));
    (authContext.userData || userData) ? authContext.setLoggedIn(true) : authContext.setLoggedIn(false);
  },[]);

  return (
    <div className='row col-12 shadow-sm mx-0'>
    <nav className="navbar navbar-light bg-light">
    <div className='row justify-content-between col-12'>
        <div className='col-6'>
            {/* <a className="navbar-brand ms-4" href="#">Equiliberty</a> */}
        </div>
        <div className='row mx-0 px-0 col-12 col-md-6 justify-content-end align-items-center'>
            <div className='col-auto d-flex'>
              <div className='message alerts me-4 position-relative'>
                <img src={message_icon}/>
              </div>
              <div className='notification alerts position-relative'>
                <img src={notification_icon}/>
              </div>              
            </div>
            <Dropdown className='col-auto username'>
            <img src={avatar_profile}/>
                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                <p className='d-inline me-4 ms-3 pe-1 userName position-relative'>
                  {userData ?`${ userData.firstName} ${userData.lastName}` : 'User'}
                </p>
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-0'>                  
                    <Dropdown.Item href="" onClick={() => setCreateUserModal(true)}>
                    <img src={Create_Account} width="20px" className='me-2'/>Create New Admin</Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => setChangePasswordModal(true)}>
                    <img src={password_icon} width="20px" className='me-2'/>Change Password</Dropdown.Item>
                    <Dropdown.Item href="" onClick={logoutUser}>
                    <img src={signout} width="20px" className='me-2'/>Log out</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div className='col-auto toggle-sidebar header_toggle cursor-pointer'>
              <img width="18" src={toggle_icon} onClick={() => setToggleSidebar(!toggleSidebar)}/>
            </div>
        </div>

    </div>
        
    </nav>
    <div className='row mx-0 px-0 logo-strap align-middle'>
        <div className='col-12 background-primary d-flex justify-content-center align-items-center'>
            <img width="135" src={logo} className='img-fluid'/>
        </div>
    </div>
    {createUserModal ? (
      <CreateUserModal setCreateUserModal={setCreateUserModal} createUserModal={createUserModal}/>) : ""
      }

{changePasswordModal ? (
      <ChangePasswordModal setChangePasswordModal={setChangePasswordModal} changePasswordModal={changePasswordModal}/>) : ""
      }
    </div>

  )
}

export default Header