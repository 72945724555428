import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast } from "../utilities/notification";

const ConfirmationModal =  (props) => {
  const [loader, setLoader] = useState(false);

  const handleDeleteData = () => {
     setLoader(true)
    props.deleteDataApi()
    .then(async (res) => {
      SuccessToast("Data deleted successfully");
    })
      .catch((error) => {
      ErrorToast("Something went wrong !!!");
      console.error("deletion error : ", error);
      })
      .finally(()=> { 
        props.setDeleteModal(false)
        setLoader(false)
      });
  };

  return (
    <div>
      <Modal
        show={props.deleteModal}
        onHide={() => props.setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <h5 className="modal-title">Confirmation</h5>
        </Modal.Header>
        <Modal.Body>
          {props.type !== "wallet-tips" ? (
            <h5>
              Are you sure you want to delete{" "}
              <span className="text-primary">{props?.deleteItem}</span>?
            </h5>
          ) : (
            <>
              <h5>Are you sure you want to delete wallet tips question: </h5>
              <h5><span>{props?.deleteItem}</span>{props?.deleteItem && !props.deleteItem.endsWith("?") ? "?" : ""}</h5>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
          variant="secondary"
          onClick={ handleDeleteData}
          >
          <div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
           Delete
          </p>
          </div>
         </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
