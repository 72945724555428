  import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../popupModals/DeleteModal";
import useGetDataServices from "../../hooks/useGetData";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate } from "react-router";
import QuestionsModal from "../../popupModals/QuestionsModal";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";
import {capitalizeFirstLetterOfWords} from "../../utilities/helpers"

const OnboardingQuestionsScreen = () => {
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const [onboardingQuestions, setOnboardingQuestions] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [onboardingQuestionsModal, setOnboardingQuestionsModal] = useState({
      visibility: false,
      type: "",
      id:""
    });
    const [deleteModal, setDeleteModal] = useState({
      visibility: false,
      deleteItem: "",
      id:""
    });

    const handleAddEditData = (type,id) => {
      setOnboardingQuestionsModal({
        ...onboardingQuestionsModal,
        visibility: true,
        type,
        id
      });
    }

    const handleDeleteModal = (data,id) => {
      setDeleteModal({...deleteModal, visibility:true, deleteItem:data,id})
    }

    const handlePublish = (id) =>{
      UpdateApiServices.publishOnboardingQuestions(id).then((res)=>{
        SuccessToast("Question Published Successfully");
        fetchOnboardingQuestionsData();
      }).catch((err)=>{
        fetchOnboardingQuestionsData();
        ErrorToast('Something Went Wrong');
      });
    };

    const handleStatus =(data)=>{
      let status = (data.isActive == true) ? false : true ;
      UpdateApiServices.updateOnboardingQuestionsStatus(data.id,status).then((res)=>{
        fetchOnboardingQuestionsData();
      }).catch((err)=>{
        fetchOnboardingQuestionsData();
        ErrorToast('Something Went Wrong');
      });
    }

    const fetchOnboardingQuestionsData = () =>{
      GetApiServices.getAllOnboardingQuestions().then((res)=>{
        (res.status === 200) && setOnboardingQuestions(res.data)      
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
      .finally(()=>setLoader(false))
    }

    useEffect(()=>{
      fetchOnboardingQuestionsData()
    },[onboardingQuestionsModal])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col ps-1">
              <h4 className="text-primary fw-bold">Onboarding Questions </h4>
            </div>
            <div className="col-auto pe-1">
              <button
                className="btn btn-outline-primary  add-button col-auto me-2"
                onClick={() => navigate("/onboarding-questions/view-results")}
              >
                View Result
              </button>
              <button
                className="btn btn-primary add-button col-auto"
                onClick={() => handleAddEditData("Add_Data")}
              >
                Add Question
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col" className="col-2">Question</th>
                    <th scope="col" className="text-center">Category</th>
                    <th scope="col" className="text-center col-2">Button Text</th>
                    <th scope="col" className="text-center">Answer type</th>
                    <th scope="col" className="text-center col-2">Token Amount</th>
                    <th scope="col" className="col-1 text-center">Status</th>
                    <th scope="col" className="col-1 text-center">Publish</th>
                    <th scope="col" className="col-2 text-center"></th>
                  </tr>
                </thead>
                <tbody className="">
                  {loader ? (
                    <tr className=" my-5 text-center">
                      {" "}
                      <th colSpan="9" className="py-5">
                        <Loader />
                      </th>
                    </tr>
                  ) : (
                    onboardingQuestions.length ? (
                    onboardingQuestions.map((curVal, index) => (
                      <tr key={index}>
                        <td scope="row" className="col-2 ">{curVal.question}</td>
                        <td className="col-1 text-center">{curVal.category.name}</td>
                        <td className="col-1 text-center">{curVal.buttonText}</td>
                        <td className="col-2 text-center">{capitalizeFirstLetterOfWords(curVal.answerType)}</td>
                        <td className="col-1 text-center">{curVal.tokenAmount}</td>
                        <td className="col-1 text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={!curVal.isPublish}
                            onChange={()=>handleStatus(curVal)}  
                            checked={curVal.isActive} />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={curVal.isPublish}
                            checked={curVal.isPublish} onClick={()=>handlePublish(curVal.id)}/>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="btn-group">
                          <button type="button" className="dot"  data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu py-0">
                           {curVal.isPublish ?
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${curVal.id}`) } >
                              <i className="fa-solid fa-eye"></i> View
                              </li>
                            </div>
                            :
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${curVal.id}`) } >
                              <i className="fa-solid fa-pen-to-square"></i> Edit
                              </li>
                            </div>}
                          </ul>
                        </div>
                        </td>
                      </tr>
                    ))
                    ) 
                    : (<>
                        <tr className=" my-5 text-center">
                      <th colSpan={7} className="py-5">
                        <h3>No Data Available</h3>
                      </th>
                    </tr>
                    </>)
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {onboardingQuestionsModal.visibility ? (
            <QuestionsModal
              type={onboardingQuestionsModal.type}
              id={onboardingQuestionsModal.id}
              questionsModal={onboardingQuestionsModal.visibility}
              setQuestionsModal={setOnboardingQuestionsModal}
            />
          ) : (
            ""
          )}
          {deleteModal.visibility ? (
            <ConfirmationModal
              deleteItem={deleteModal.deleteItem}
              deleteModal={deleteModal.visibility}
              setDeleteModal={setDeleteModal}
              setLoader={setLoader}
              id={deleteModal.id}
              deleteDataApi={() => DeleteApiServices.deleteOnboardingScreen(deleteModal.id)}
              // setNewData={setUserList}
              // fetchNewDataApi={() => GetApiServices.getAlluserlist()}
            />
          ) : (
            ""
          )}
        </div>
        </>
      );
}

export default OnboardingQuestionsScreen