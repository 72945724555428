import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData, capitalizeFirstLetterOfWords } from "../../utilities/helpers";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";
import TokenModal from "../../popupModals/TokenModal";

const TokenManagement = () => {
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(true);
    const [disableToggle, setDisableToggle] = useState({ toggle:false, id:""});
    const [tokenList, setTokenList] = useState([]);
    const [tokenModal, setTokenModal] = useState({
        visibility:false,
        type:"",
        id:""
    })
    const handleAddEditData = (type,id) =>{
        setTokenModal({
          ...tokenModal,
          visibility: true,
          type,
          id
        });
      }

    //For status toggle
    const handleStatusChange = (id,toggle) => {
      setDisableToggle({toggle:true, id})
      let status = toggle ? false : true ;
      UpdateApiServices.updateTokenStatus(id, status)
      .then((res) => {
      fetchData(GetApiServices.getAllTokens(), setTokenList,setLoader)
      SuccessToast("Status updated successfully");
    })
      .catch((err)=>ErrorToast('Something Went Wrong'))
      .finally(()=>setDisableToggle({toggle:false, id}))
      
    }
    useEffect(()=>{
      setLoader(true)
      fetchData(GetApiServices.getAllTokens(), setTokenList,setLoader);
    },[])
    useEffect(()=>{
      fetchData(GetApiServices.getAllTokens(), setTokenList,setLoader);
    },[tokenModal])
    
    return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">Token Management</h4>
          </div>
          <div className="col-auto pe-1">
            <button
              className="btn btn-primary add-button col-auto"
              onClick={() => handleAddEditData("Add_Data")} >
              Add Token
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-12 col-md-12 table-responsive fixed-table"
            style={{ overflowX: "auto" }}
          >
            <table class="table table-fit  align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className=" table-header-font align-middle">
                  <th scope="col" className="col-2 text-center">Token Name</th>
                  <th scope="col" className="col-3 text-center">Type</th>
                  <th scope="col" className="col-3">Description</th>
                  <th scope="col" className="col text-center">Token Amount</th>
                  <th scope="col" className="col text-center">Status</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody className="">
                {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="6" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) : tokenList.length ? (
                  tokenList.map((token, index)=>(
                    <>
                    <tr key={index}>
                    <td className="col-2 text-center">{token.title}</td>
                    <td className="col-2 text-center">{capitalizeFirstLetterOfWords(token.type)}</td>
                    <td className="col-3 text-justify" dangerouslySetInnerHTML={{ __html: token.description }}></td>
                    <td className="col text-center">{token.tokenAmount}</td>
                    <td className="text-center">
                    <div className="form-switch">
                      <input className="form-check-input custom-control-input" type="checkbox" role="switch" checked={token.isActive} onChange={()=> handleStatusChange(token.id,token.isActive)} disabled={disableToggle.toggle && token.id === disableToggle.id}/>
                    </div>
                    </td>
                    <td className="text-center">
                    <div class="btn-group">
                      <button type="button" class="dot" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                        <ul class="dropdown-menu py-0" >
                        <li><a class="dropdown-item"  onClick={() => handleAddEditData("Edit_Data", `${token.id}`) }><i class="fa-solid fa-pen-to-square"></i> Edit</a></li>
                        <li><a class="dropdown-item"  onClick={() => handleAddEditData("View_Data", `${token.id}`) }><i class="fa-solid fa-eye"></i> View</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                    </>
                  )) 
                ) : (
                  <>
                    <tr>
                      <td colSpan={6} className="text-center py-5">
                        <h4 className="text-muted">No Data Available</h4>
                        </td>
                    </tr>
                  </>
                )
                
                }
              </tbody>
            </table>
          </div>
        </div>
        {tokenModal.visibility ? (
          <TokenModal
            type={tokenModal.type}
            tokenModal={tokenModal.visibility}
            setTokenModal={setTokenModal}
            id={tokenModal.id}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default TokenManagement