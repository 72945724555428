import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast, InformationToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import usePutDataServices from "../hooks/usePutDataServices";
import useGetDataServices from "../hooks/useGetData";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const CategoryModal = (props) => {
    const modelRef = useRef();
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    // eslint-disable-next-line
    const [validation, setValidation] = useState();
    const navigate = useNavigate();
  const authContext = useContext(AuthContext);
    const handleInputs = (e) => {
        setCategoryName(e.target.value)
      };

    const handleCategory =()=>{
      setLoader(true);
      
      if(categoryName.trim() === ""){
        InformationToast("Please Enter Category Name");
        setLoader(false);
      }else if(categoryName.trim().length < 3){
        InformationToast("Category Name must be at least 3 character long");
        setLoader(false);
      }else{
        if(props.categoryModal.type === "Add_Data"){
          PostApiServices.AddCategory({name:categoryName}).then((res)=>{
            if(res.status === 201){
              props.setCategoryModal({
              visibility: false,
              type: "",
              id: "",
            })
            SuccessToast("Category Added Successfully");
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{
            ErrorToast("Something went wrong  !!!");
          }
          }).catch((errors)=> {
            if(errors?.response?.data?.statusCode === 409){
              InformationToast("Category with this name already exists.");
            }else{
            ErrorToast("Something went wrong err!!!")
          }
          })
          .finally(()=>setLoader(false))
        }else{
          UpdateApiServices.updateCategory({name:categoryName},props.categoryModal.id).then((res)=>{
            res && props.setCategoryModal({
              visibility: false,
              type: "",
              id: "",
            })
            SuccessToast("Category Updated Successfully");
          }).catch((errors)=>ErrorToast("Something went wrong !!!"))
          .finally(()=>setLoader(false))
        }
      }
    }

    useEffect(()=>{
      !(props.categoryModal.type === "Add_Data") && 
        GetApiServices.getCategoryByID(props.categoryModal.id).then((res)=>{
          (res.status === 200) && setCategoryName(res.data.name)
        }).catch((err)=>{
          ErrorToast('Error while getting Category data !')
        })
    },[])


    return (
        <div>
          <Modal
            show={props.categoryModal.visibility}
            onHide={() => props.setCategoryModal(true)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <h5 className="modal-title">{props.type === "Add_Data" ? "Add" : "Edit"} Category</h5>
            </Modal.Header>
            <Modal.Body ref={modelRef} >
              {validation === false && (
                <div class="alert alert-danger fw-bold" role="alert">
                  Please fill out all the fields !!!
                </div>
              )}
              <form>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label mb-1">
                    Category Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={categoryName}
                    onChange={handleInputs}
                  />
                </div>                
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => handleCategory()}
                disabled={loader}
            >
            <div className="d-flex align-center">
              {loader ? 
              <>
              <div className="center"><div className="loader"></div></div>
              </>
              : ""}
              <p className="d-inline mx-2 my-0">
              {props.categoryModal.type === "Add_Data" ? "Add" : "Update"}
              </p>
              </div>
            </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
}

export default CategoryModal