import React, { useEffect } from "react";
import { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  SuccessToast,
  ErrorToast,
  InformationToast,
} from "../utilities/notification";
import {convertHtmlToText,convertHtmlToPlainText} from "../utilities/helpers"
import { useState } from "react";
import usePutDataServices from "../hooks/usePutDataServices";
import useGetDataServices from "../hooks/useGetData";
import usePostDataServices from "../hooks/usePostData";
import AuthContext from "../context/AuthProvider";
import TextEditor from "../components/common/TextEditor";
import { useContext } from "react";
import { useNavigate } from "react-router";

const NotificationModal = (props) => {
  const modelRef = useRef();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const GetApiServices = useGetDataServices();
  const PostApiServices = usePostDataServices();
  const UpdateApiServices = usePutDataServices();
  const [loader, setLoader] = useState(false);
  const [editorState,setEditorState]=useState("");
  const [notificationData, setNotificationData] = useState({
    criteria: "",
    type: "",
    title: "",
    body: ""
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState(true);
  const [contentLength, setContentLength] = useState();

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setNotificationData({ ...notificationData, [name]: value.trimStart().replace(/\s{2,}/g, ' ') });
    removeValidations();
  };
  
  // const onTextEditorChange = (e) =>{
  //   setEditorState(e)
  //   removeValidations();
  // }

  const formValidation = () => {
    for (const key of Object.keys(notificationData)) {
      if (key !== "body" && (notificationData[key] === "" || notificationData[key].trim() === "")) {
        invalidFields.push(key);
        setValidation(false);
        modelRef.current.scrollTop = 0;
      }
    }
    // let bodyField = notificationData.type === "EMAIL" ? convertHtmlToText(editorState) : convertHtmlToPlainText(notificationData.body);
    let bodyField = notificationData.type === "EMAIL" ? convertHtmlToText(notificationData.body) : convertHtmlToPlainText(notificationData.body);
    if(bodyField === ""){
      invalidFields.push(bodyField);
      setValidation(false);
      modelRef.current.scrollTop = 0;
    }
    if(validation && !invalidFields.length ){
      handleSubmit()
    }
  };

  const handleSubmit = () => {
    setLoader(true)
    let data = {
      criteria: notificationData.criteria,
      type: notificationData.type,
      title: notificationData.title,
      // body: notificationData.type === "EMAIL" ? editorState : convertHtmlToPlainText(notificationData.body)
      body: notificationData.type === "EMAIL" ? notificationData.body : convertHtmlToPlainText(notificationData.body)
    }
    if(props.id){
      UpdateApiServices.updateNotification(data, props.id)
      .then((res)=> {
        if (res.status === 401) {
          InformationToast("Session has been expired. Please login again !!!");
          localStorage.clear();
          authContext.setLoggedIn(false)
          navigate("/");
        }else if(res.status === 200){
          SuccessToast("Data Updated Successfully.");
          props.setNotificationModal(false)
        }else{
          ErrorToast("Something went wrong while updating the data !!!");
        }
      })
      .catch((err)=>{
        ErrorToast("Something went wrong while updating the data !!!");
        console.log("err", err);
      })
      .finally(()=>setLoader(false))
    }else{
      PostApiServices.addNotification(data)
      .then((res)=> {
        if (res.status === 401) {
          InformationToast("Session has been expired. Please login again !!!");
          localStorage.clear();
          authContext.setLoggedIn(false)
          navigate("/");
        }else if(res.status === 201){
          SuccessToast("Data Added Successfully.");
          props.setNotificationModal(false)
        }else{
          ErrorToast("Something went wrong while Adding the data !!!");
        }
      })
      .catch((err)=>{
        ErrorToast("Something went wrong while adding data !!!");
        console.log("err", err);
      })
      .finally(()=>setLoader(false))
    }
  }

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };

  useEffect(() => {
    // fetchData(
    //   GetApiServices.getNotificationById(props.id),
    //   setNotificationData,
    //   setLoader
    // );
    if(props.id){
      GetApiServices.getNotificationById(props.id)
      .then((res)=>{
        setNotificationData(res.data)
        setEditorState(res.data.body)
      })
    }
  }, [props.id]);

  useEffect(()=>{
    notificationData.type === "PUSH_NOTIFICATION" ?  setContentLength(178) : notificationData.type === "SMS" ? setContentLength(100) : setContentLength("") 
  },[notificationData.type])

  return (
    <div>
      <Modal
        show={props.notificationModal}
        onHide={() => props.setNotificationModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.type === "Add_Data" ? "Add" : props.type === "Edit_Data" ? "Edit" : "View"} Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modelRef} className="modal-height">
        {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
            </div>
          )}
          <form>            
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Criteria of Notification
              </label>
              <select
                className="form-select custom-select"
                id="criteria"
                name="criteria"
                value={notificationData.criteria}
                onChange={handleInputs}
                disabled={props.type === "View_Data"}
              >
                <option value="" disabled selected className="my-2 py-5">
                  select Criteria
                </option>
                <option value="PROMOTION">Promotion</option>
                <option value="OFFER">Offer</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Notification Type
              </label>
              <select
                className="form-select custom-select"
                name="type"  
                id="type"              
                value={notificationData.type}
                onChange={handleInputs}
                disabled={props.type === "View_Data"}
              >
                <option value="" disabled selected className="my-2 py-5">
                  select Type
                </option>
                <option value="PUSH_NOTIFICATION">Push Notification</option>
                <option value="EMAIL">Email</option>
                <option value="SMS">SMS</option>                
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={notificationData.title}
                onChange={(e)=>{
                  const { value } = e.target;
                  setNotificationData({ ...notificationData, title: value });
                  removeValidations();
                }}
                maxLength={30}
                disabled={props.type === "View_Data"}
              />
            </div>
              <div className="mb-4">
                <label htmlFor="body" className="form-label">
                  Body
                </label>
                <TextEditor
                  className="form-control"  
                  name="body"
                  // value={editorState} 
                  value={notificationData.body} 
                  onTextEditorChange={(e)  => setNotificationData({...notificationData, body:e})}
                  readOnly={(props.type === "View_Data") ? true : false}
                  actionType={props.type} />
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer className={props.type === "View_Data" ? "d-none" : ""}>
          <Button
            variant="primary"
            onClick={() => formValidation()}
            disabled={loader}
          >
            <div className="d-flex align-center">
              {loader ? (
                <>
                  <div className="center">
                    <div className="loader"></div>
                  </div>
                </>
              ) : (
                ""
              )}
              <p className="d-inline mx-2 my-0">{props.type === "Edit_Data" ? "Update" : "Add"}</p>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NotificationModal;
