import React from 'react'
import LoaderGif from "../../assets/images/equivi_loader.gif";

const Loader = () => {
  return (
     <div className='gif-loader'> 
        <img src={LoaderGif} height="80px" width="85px"/>
    </div>
  )
}

export default Loader;