import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import AuthContext from "../context/AuthProvider";
import useGetDataServices from "../hooks/useGetData";
import usePostDataServices from "../hooks/usePostData";
import usePutDataServices from "../hooks/usePutDataServices";
import { ErrorToast, InformationToast, SuccessToast } from "../utilities/notification";
import TextEditor from "../components/common/TextEditor";
import { convertHtmlToText } from "../utilities/helpers";

const AddNewsModal = (props) => {
    const modelRef = useRef();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [newsInfo, setNewsInfo] = useState({
        title: "",
        content: "",
        type: "",
        newsStatus: "",
        zipCode: ""
    });
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
    const [editorTitleState,setEditorTitleState]=useState("");
    const [editorContentState,setEditorContentState]=useState("");

    const handleInputs = (e, id) => {
      const { name, value } = e.target;
      setNewsInfo({ ...newsInfo, 
        [name]: typeof value === "string" ? 
        value.trimStart().replace(/[^a-z\s]/ig, '').replace(/\s{2,}/g, ' ') : value
      });
      removeValidations();
      setLoader(false)
    };


    const handleAddUpdateData =(id)=>{
      let data = {
        "title": editorTitleState,
        "content": editorContentState,
        "type": newsInfo.type,
        "newsStatus": newsInfo.newsStatus,
        "zipCode": newsInfo.zipCode.toString()
      }
      if(props.id){
        UpdateApiServices.updateNews(data, props.id).then((res)=>{
          props.setAddNewsModal(false)
          if(res.status === 200){
            SuccessToast("News updated successfully")
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{
            ErrorToast('Something went wrong while Updating data')
          }
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Updating data')
        })
        .finally(() => setLoader(false))
      }else{
        PostApiServices.addNews(data).then((res)=>{
          props.setAddNewsModal(false)
          if(res.status === 201){
            SuccessToast("News added successfully")
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{ 
            ErrorToast('Something went wrong while Updating data');
          }
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Adding data')
        })
        .finally(() => setLoader(false))
      }
    }

    const formValidation = () => {
      setLoader(true)
      for (const key of Object.keys(newsInfo)) {
        if (newsInfo[key] === "" && key !== "content" && key !== "title") {
          invalidFields.push(key)
          setValidation(false);
          setLoader(false);
          modelRef.current.scrollTop = 0;
          setLoader(false);
        }
      }
      let contentField = convertHtmlToText(editorContentState);
      let overviewField = convertHtmlToText(editorTitleState);
      if(contentField === "" || overviewField === ""){
        invalidFields.push(contentField);
        setValidation(false);
        setLoader(false);
        modelRef.current.scrollTop = 0;
      }
      if (validation && !invalidFields.length) {
        handleAddUpdateData();
      }
    };

    const removeValidations = () => {
      setValidation(true);
      setInvalidFields([]);
    };

    useEffect(()=>{
      if(props.type === "Edit_Data" || props.type === "View_Data"){
      GetApiServices.getNewsById(props.id).then((res)=>{
        const data = res.data;
        setNewsInfo({
          title: data.title,
          content: data.content,
          type: data.type,
          newsStatus: data.newsStatus,
          zipCode: data.zipCode
        })
        setEditorTitleState(data.title)
        setEditorContentState(data.content)
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
    } },[])

    const onTextEditorChange = (e) =>{
      setEditorContentState(e)
      removeValidations();
    }

    const onTextEditorOverviewChange = (e) =>{
      setEditorTitleState(e)
      removeValidations();
    }

  return (
    <div>
    <Modal
      show={props.addNewsModal}
      onHide={() => props.setAddNewsModal(true)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.type === "Add_Data" ? 'Add' : props.type === "Edit_Data" ? "Edit" : "View"} News</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modelRef} className="modal-height">
        {validation === false && (
          <div class="alert alert-danger fw-bold" role="alert">
            Please fill out all the fields !!!
            {/* <h5 className="fw-bold text-danger mt-3 mb-4"></h5> */}
          </div>
        )}
        <form>
          <div className="mb-4">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <TextEditor
              value={editorTitleState} 
              onTextEditorChange={onTextEditorOverviewChange}
              readOnly={(props.actionType === "View_Data") ? true : false}
              actionType={props.actionType} />
          </div>

          <div className="mb-4">
              <label htmlFor="title" className="form-label">
                 Type
              </label>
              <select
                className="form-select" value={newsInfo.type}
                onChange={handleInputs}
                disabled = {props.type === "View_Data"}
                name="type" id = "type">
                  <option value="" disabled selected>Select News Type</option>
                  <option value="NORMAL">Normal</option>
                  <option value="ANNOUNCEMENT">Announcement</option>
                  <option value="MEETING">Meeting</option>
              </select>
          </div>
            <div className="mb-4">
                <label htmlFor="image" className="form-label">
                    Content
                </label>
                <TextEditor
                  value={editorContentState} 
                  onTextEditorChange={onTextEditorChange}
                  readOnly={(props.actionType === "View_Data") ? true : false}
                  actionType={props.actionType} />
            </div>
            
          <div className="mb-4">            
            <label htmlFor="title" className="form-label">
              News Status
            </label>
                <select
                    className="form-select" value={newsInfo.newsStatus}
                    onChange={handleInputs}
                    disabled = {props.type === "View_Data"}
                    name="newsStatus" id = "newsStatus">
                    <option value="" disabled selected>Select News Status</option>
                    <option value="LATEST">Latest</option>
                    <option value="NORMAL">Normal</option>
                    <option value="OLD">Old</option>
                </select>
             </div>
              <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                  Zip code
                  </label>
                  <input
                  type="text"
                  className="form-control"
                  name="zipCode"
                  min={0}
                  disabled = {props.type === "View_Data"}
                  value={newsInfo.zipCode}
                  // onChange={(e) => {
                  //   let ZipcodeValue = e.target.value;
                  //     if (ZipcodeValue.length <= 5) {
                  //       if (/^\d*$/.test(ZipcodeValue)) {
                  //         const parsedValue = parseInt(ZipcodeValue, 10);
                  //         const newZipCode = !isNaN(parsedValue) ? Math.abs(parsedValue) : '';
                  //         setNewsInfo({ ...newsInfo, zipCode: newZipCode });
                  //         removeValidations();
                  //         setLoader(false)
                  //         }
                  //       }
                  // }}   
                  onChange={(e) => {
                    const zipCodeValue = e.target.value;
                    if (zipCodeValue.length <= 5) {
                      if (/^\d*$/.test(zipCodeValue)) {
                        setNewsInfo({ ...newsInfo, zipCode: zipCodeValue });
                        removeValidations();
                        setLoader(false);
                      }
                    }
                  }}
                   
                  />
                  <p className="text-muted">Use zip code 00000 for showing news in all communities</p>
              </div>
        </form>
      </Modal.Body>
      {!(props.type === "View_Data") &&
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={loader}
          onClick={formValidation}
        >
        <div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
      </Modal.Footer>}
    </Modal>
  </div>
  )
}

export default AddNewsModal