import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Accordion from 'react-bootstrap/Accordion';
import { fetchData } from "../utilities/helpers";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import { useLocation, useParams } from "react-router";
import { Button } from "react-bootstrap";

const ViewResponseModal = (props) => {
    const modelRef = useRef();
    const location = useLocation();
    const params = useParams();
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const imageServices = useImageServices();
    const [loader, setLoader] = useState(true);
    const [userResponse, setUserResponse] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
  
    useEffect(()=>{
      if(props.id){
        location.pathname === "/onboarding-questions/view-results" ?
        fetchData(GetApiServices.getOnboardingUserAnswers(props.id),setUserResponse,setLoader) 
        : fetchData(GetApiServices.getSurveyAnswerByUser(props.id,params.id),setUserResponse,setLoader)
      }
    },[])

    return (
  <div>
    <Modal
      show={props.userResponseModal}
      onHide={() => props.setUserResponseModal(true)}
      backdrop="static"
      keyboard={false}
      className="faq_modal"
    >
        <Modal.Header closeButton>
            <h5 className="modal-title">
            User Response
            {/* Response from {userResponse[0].user.firstName} {userResponse[0].user.lastName} */}
            </h5>
        </Modal.Header>
      <Modal.Body ref={modelRef} className="modal-height">
      {loader ? (
        <div className="text-center">
            <Loader />
        </div>
      ):(
        <Accordion className="faq_main">
            {userResponse.map((response)=>(
                <Accordion.Item eventKey={response.id} className="m-0 mb-3">
                <Accordion.Header>
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <p className="m-0">Q. {response.question.question}</p>
                        {/* <span>{response.updatedAt}</span> */}
                    </div>
                </Accordion.Header>
                <Accordion.Body className="p-0 my-3">
                    <ul className="ans m-0 p-0 list-unstyled">
                    {response.answer.map((answer, id)=> (
                        <li><span>{id === 0 && "Ans."}</span>{answer}</li>
                    ))}
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            )) }
            
        </Accordion>
      )}
       
      </Modal.Body>
      
    </Modal>
  </div>
  )
}

export default ViewResponseModal