import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast } from "../utilities/notification";

const SendNotificationModal = (props) => {
    const [loader,setLoader]=useState(false);

    const handleSendNotification = async() => {
        setLoader(true);
        
       await props.sendNotificationApi()
        .then(async(res)=>{
            SuccessToast("Notification has been sent.");
        })
        .catch((err)=>{
            ErrorToast("Something went wrong !!!");
            console.error("Notification sending Error",err)
        })
        .finally(()=>{
            props.setSendNotificationModal(false)
            setLoader(false)
        });
    }

    return(
        <Modal
         show={props.sendNotificationModal}
         onHide={()=>props.setSendNotificationModal(false)}
         backdrop="static"
         keyboard={false}
        >
            <Modal.Header closeButton>
                <h5 className="modal-title">Send Notification Confirmation</h5>
            </Modal.Header>
            <Modal.Body>
            <h5>
                Are you sure you want to Send {" "}
                <span className="text-primary">{props.sendItem}</span>?
            </h5>
            </Modal.Body>
            <Modal.Footer>
            <Button
            variant="primary"
            onClick={handleSendNotification}
            >
            <div className="d-flex align-center">
            {loader ? 
            <>
            <div className="center"><div className="loader"></div></div>
            </>
            : ""}
            <p className="d-inline mx-2 my-0">
            Send
            </p>
            </div>
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SendNotificationModal;