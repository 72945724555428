import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData } from "../../utilities/helpers";
import usePutDataServices from "../../hooks/usePutDataServices";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import Loader from "../common/Loader";
import htmlTruncate from 'html-truncate';
import AddWalletTipsModal from "../../popupModals/AddWalletTipsModal";
import ConfirmationModal from "../../popupModals/DeleteModal";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";

const WalletTips = () => {

    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const deleteWalletTips = useDeleteDataServices();
    const [allWalletTips, setAllWalletTips] = useState([]);
    const [loader, setLoader] = useState(true);

    const [addWalletTipsModal, setAddWalletTipsModal] = useState({
        visibility: false,
        type: "",
        id: "",
    });

    const [deleteModal, setDeleteModal] = useState({
        visibility: false,
        deleteItem: "",
        id: ""
    })

    const handleAddEditData = (type, id) => {
        setAddWalletTipsModal({
            ...addWalletTipsModal,
            visibility: true,
            type,
            id,
        });
    };

    const handleStatus = (data) => {
        let status = data.isActive ? false : true;

        UpdateApiServices.updateWalletTipsStatus(data.id, status)
            .then((res) => {
                SuccessToast(`Status changed successfully`)
                fetchData(GetApiServices.getAllWalletTips(), setAllWalletTips, setLoader)
            })
            .catch((err) => {
                ErrorToast('Something Went Wrong');
            })
    }

    useEffect(() => {
        fetchData(GetApiServices.getAllWalletTips(), setAllWalletTips, setLoader)
    }, [addWalletTipsModal]);

    useEffect(() => {
        fetchData(GetApiServices.getAllWalletTips(), setAllWalletTips, setLoader);
    }, [addWalletTipsModal, deleteModal])

    const truncateHtml = (html, maxLength) => {
        if (!html) {
            return '';
        }
        return htmlTruncate(html, maxLength, { ellipsis: '...' });
    };

    const handleDeleteModal = (data, id) => {
        setDeleteModal({ ...deleteModal, visibility: true, deleteItem: data, id })
    }

    return (
        <>
            <div className="container-fluid jumbotron shadow-sm">
                <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
                    <div className="col ps-1">
                        <h4 className="text-primary fw-bold">Wallet Tips</h4>
                    </div>

                    <div className="col-auto pe-1">
                        <button
                            className="btn btn-primary add-button col-auto"
                            onClick={() => handleAddEditData("Add_Data")}
                        >
                            Add Wallet Tips
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div
                        className="col-12 col-md-12 table-responsive fixed-table"
                        style={{ overflowX: "auto" }}
                    >
                        <table class="table table-fit  align-middle">
                            <thead className="table-light sticky-position table-header-height">
                                <tr className=" table-header-font align-middle">
                                    <th scope="col" className="">Question</th>
                                    <th scope="col" className="col-3">Answer</th>
                                    <th scope="col" className="col-1 text-center">Status</th>
                                    <th scope="col" className="col-1 text-center"></th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {loader ? (
                                    <tr className=" my-5 text-center">
                                        <th colSpan="9" className="py-5">
                                            <Loader />
                                        </th>
                                    </tr>
                                ) : allWalletTips.length ? (
                                    allWalletTips.map((walletTips, index) => (
                                        <tr key={index}>
                                            <td className="col-3 cursor-pointer" dangerouslySetInnerHTML={{ __html: truncateHtml(walletTips?.question, 50) }}
                                                onClick={() => walletTips?.isPublish
                                                    ? handleAddEditData("View_Data", walletTips?.id)
                                                    : handleAddEditData("Edit_Data", walletTips?.id)
                                                }></td>
                                            <td className="col-3 cursor-pointer" dangerouslySetInnerHTML={{ __html: truncateHtml(walletTips?.answer, 50) }}
                                                onClick={() => walletTips?.isPublish
                                                    ? handleAddEditData("View_Data", walletTips?.id)
                                                    : handleAddEditData("Edit_Data", walletTips?.id)
                                                }></td>
                                            <td className="col-1 text-center">
                                                <div className="form-switch">
                                                    <input className="form-check-input custom-control-input" type="checkbox" role="switch"
                                                        checked={walletTips.isActive} onChange={() => handleStatus(walletTips)} defaultChecked />
                                                </div>
                                            </td>
                                            <td className="text-center col-1">
                                                <div className="btn-group">
                                                    <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu py-0">
                                                        <div className="">
                                                            <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${walletTips.id}`)} >
                                                                <i className="fa-solid fa-eye"></i> View
                                                            </li>
                                                        </div>
                                                        <div className="">
                                                            <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${walletTips.id}`)} >
                                                                <i className="fa-solid fa-pen-to-square"></i> Edit
                                                            </li>
                                                        </div>
                                                        <div className="">
                                                            <li className="dropdown-item" onClick={() => handleDeleteModal(`${walletTips.question}`, `${walletTips.id}`)} >
                                                                <i className="fa-solid fa-trash"></i> Delete
                                                            </li>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <>
                                        <tr className=" my-5 text-center">
                                            <th colSpan={8} className="py-5">
                                                <h4 className="text-muted">No Data Available</h4>
                                            </th>
                                        </tr>
                                    </>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                {addWalletTipsModal.visibility ? (
                    <AddWalletTipsModal
                        addWalletTipsModal={addWalletTipsModal}
                        setAddWalletTipsModal={setAddWalletTipsModal}
                        walletTipsId={addWalletTipsModal.id}
                        actionType={addWalletTipsModal.type}
                    />
                ) : (
                    ""
                )}

                {deleteModal.visibility ? (
                    <ConfirmationModal
                        deleteItem={deleteModal.deleteItem}
                        deleteModal={deleteModal.visibility}
                        setDeleteModal={setDeleteModal}
                        setLoader={setLoader}
                        id={deleteModal.id}
                        deleteDataApi={() => deleteWalletTips.deleteWalletTips(deleteModal.id)}
                        type="wallet-tips"
                    />
                ) : (
                    ""
                )}
            </div>
        </>
    );
}

export default WalletTips;