import useAxios from './useAxios';
import { fileUploadingConfig, svcconfig } from '../utilities/api-confige';

const usePostDataServices = () => {
    const axios = useAxios();

    const uploadImgToServer = (data) => {
        const apiUrl = '/uploadtos3';
        return axios.post(apiUrl, data, {headers : fileUploadingConfig()})
    }

    const AddWelcomeScreen = (data) => {
        const apiUrl = '/web-admin/welcomeScreen';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }
    
    const AddOnboardingScreen = (data) => {
        const apiUrl = '/web-admin/onboardingScreen';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddCategory = (data) => {
        const apiUrl = '/web-admin/categories';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddOnboardingQuestions = (data) => {
        const apiUrl = '/web-admin/onboardingQuestion';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddCommunityProfile =(data)=>{
        const apiUrl = '/web-admin/communities';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddCommunityPosts =(data)=>{
        const apiUrl = '/web-admin/posts';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddToken = (data) => {
        const apiUrl = '/web-admin/token-activities';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddWalletTips = (data) => {
        const apiUrl = '/web-admin/wallet-tips';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddSurvey = (data) => {
        const apiUrl = '/web-admin/survey';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddSurveyQuestion = (data) => {
        const apiUrl = '/web-admin/survey/question';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddAsset = (data) => {
        const apiUrl = '/web-admin/assets';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddBadge = (data) => {
        const apiUrl = '/web-admin/badges';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const AddJourneyGoal = (data) => {
        const apiUrl = '/web-admin/journey-goals';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const addAdvertisementType = (data) => {
        const apiUrl = '/web-admin/advertisements/type';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }

    const addNews = (data) => {
        const apiUrl = '/web-admin/news';
        return axios.post(apiUrl, data, {headers : svcconfig()})
    }
    const addAdvertisement = (data) => {
        const apiUrl = `/web-admin/advertisements`
        return axios.post(apiUrl, data,{headers : svcconfig()})
    }

    const addUser = (data) =>{
        const apiUrl = `auth/web-admin/user`
        return axios.post(apiUrl,data, {headers : svcconfig()})
    }

    const addQRCode = (data) => {
        const apiURL = `/web-admin/qr-codes/business`
        return axios.post(apiURL,data,{headers : svcconfig()})
    }
    
    const addGeofencingBusiness = (data) =>{
        const apiUrl = `/web-admin/geofencing/business`
        return axios.post(apiUrl,data, {headers : svcconfig()})
    }

    const addNotification = (data) =>{
        const apiUrl = `/web-admin/notification`
        return axios.post(apiUrl,data, {headers : svcconfig()})
    }

    const sendNotification = (notificationId) =>{
        const apiUrl = `/web-admin/notification/send/${notificationId}`
        return axios.post(apiUrl, {headers : svcconfig()})
    }
    
  return {
    uploadImgToServer,
    AddWelcomeScreen,
    AddOnboardingScreen,
    AddCategory,
    AddOnboardingQuestions,
    AddToken,
    AddWalletTips,
    AddSurvey,
    AddSurveyQuestion,
    AddCommunityProfile,
    AddCommunityPosts,
    AddAsset,
    AddBadge,
    AddJourneyGoal,
    addAdvertisementType,
    addNews,
    addAdvertisement,
    addUser,
    addQRCode,
    addGeofencingBusiness,
    addNotification,
    sendNotification
  }
}

export default usePostDataServices;