import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast,InformationToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const AddWalletModal = (props) => {
    const modelRef = useRef();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [validation, setValidation] = useState();
    const [advertisementType,setAdvertiseMentType] = useState({
      name:""
    })

    useEffect(()=>{
      if(props.id){
        GetApiServices.getAdvertisementTypebyId(props.id).then((res)=>{
          setAdvertiseMentType({
            name : res.data.name
          })
        })
      }
    },[])

    const handleAddUpdate =()=>{
      setLoader(true)
      if(advertisementType.name){
        if(props.id){
          UpdateApiServices.updateAdvertisementTypeById(advertisementType,props.id).then((res)=>{
            props.setAddWalletModal(false)
            if(res.status == 200){ 
              SuccessToast("AdvertisementType Updated Sucessfully");
            }else if (res.status === 401) {
              InformationToast("Session has been expired. Please login again !!!");
              localStorage.clear();
              authContext.setLoggedIn(false)
              navigate("/");
            }else{ErrorToast("Something went wrong !!!");}
          }).catch((err)=>{
            ErrorToast('Something went wrong while Updating data')
          }).finally(() => setLoader(false))
        }else{
          PostApiServices.addAdvertisementType(advertisementType).then((res)=>{
            props.setAddWalletModal(false)
            if(res.status == 201){
              SuccessToast("AdvertisementType Added Sucessfully");
            }else if (res.status === 401) {
              InformationToast("Session has been expired. Please login again !!!");
              localStorage.clear();
              authContext.setLoggedIn(false)
              navigate("/");
            }else{ErrorToast("Something went wrong!!!");}
          }).catch((err)=>{
            ErrorToast('Something went wrong while Adding data')
          }).finally(() => setLoader(false))
        }
      }else{
        setValidation(true);
        setLoader(false);
      }
    }

    return (
        <div>
          <Modal
            show={props.addWalletModal}
            onHide={() => props.setAddWalletModal(true)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
            <Modal.Title>{props.type === "Add_Data" ? 'Add' :"Edit"} Advertise Type</Modal.Title>

            </Modal.Header>
            <Modal.Body ref={modelRef} className="">
              {validation === true && (
                <div class="alert alert-danger fw-bold" role="alert">
                  Please fill out all the fields !!!
                    </div>
              )}
              <form>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label mb-1">
                    Advertise Type
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="advertisementType"
                    name="advertisementType"
                    value={advertisementType.name}
                    onChange={(e)=>{
                      const { value } = e.target;
                      const cleanedValue = value.trimStart().replace(/\s{2,}/g, ' ');
                      setAdvertiseMentType({ ...advertisementType, name: cleanedValue });
                      setValidation(false)
                    }}
                  />
                </div>                
                
              </form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button
                variant="primary"
                onClick={() => handleAddUpdate()}
                disabled={loader}
              >
                {loader ? <Loader /> : ""}
                <p className="d-inline mx-2">
                  Add
                </p>
              </Button> */}
              <Button
              variant="primary"
              onClick={() => handleAddUpdate()}
              disabled={loader}
            ><div className="d-flex align-center">
              {loader ? 
              <>
              <div className="center"><div className="loader"></div></div>
              </>
              : ""}
              <p className="d-inline mx-2 my-0">
               {props.type === "Add_Data" ? "Add" : "Update"}
              </p>
              </div>
            </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
}

export default AddWalletModal