import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, InformationToast, SuccessToast } from "../utilities/notification";
import { convertHtmlToText } from "../utilities/helpers";
import usePostDataServices from "../hooks/usePostData";
import usePutDataServices from "../hooks/usePutDataServices";
import useGetDataServices from "../hooks/useGetData";
import TextEditor from "../components/common/TextEditor";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const AddWalletTipsModal = (props) => {
    const modelRef = useRef();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
    const [editorState, setEditorState] = useState("");
    const [updatedWalletTipsInfo, setUpdatedWalletTipsInfo] = useState({});
    const [walletTipsData, setWalletTipsData] = useState({
        question: "",
        answer: "",
    });
    const [count, setCount] = useState(0)

    const handleInputs = (e) => {
        const { name, value } = e.target;

        if (name === "question") {
            if (value.length > 150) {
                // ErrorToast("Character limit exceeded! Maximum 150 characters allowed.");
                return; // Prevent further processing if character limit is exceeded
            }
            setCount(value.length);
        }

        setWalletTipsData({
            ...walletTipsData,
            [name]: typeof value === "string" ? value.trimStart().replace(/\s{2,}/g, ' ') : value
        })
        updateInputs(name, value);
        removeValidations();
    };

    const onTextEditorChange = (e) => {
        setEditorState(e)
        removeValidations();
    }

    const removeValidations = () => {
        setValidation(true);
        setInvalidFields([]);
    };

    const updateInputs = (...data) => {
        setUpdatedWalletTipsInfo({ ...updatedWalletTipsInfo, [data[0]]: data[1] });
        removeValidations();
    };

    const datavalidation = () => {
        setValidation(true);
        setInvalidFields([]);
        for (const keys of Object.keys(walletTipsData)) {
            if (walletTipsData[keys] === "" && keys !== "answer") {
                invalidFields.push(keys);
                setValidation(false);
                modelRef.current.scrollTop = 0;
                break
            }
        }
        let answerField = convertHtmlToText(editorState);
        if (answerField === "") {
            invalidFields.push(answerField);
            setValidation(false);
            modelRef.current.scrollTop = 0;
        }
        if (validation && !invalidFields.length) {
            handleSubmit();
        }
        else {
            setValidation(false);
        }
    }

    const handleSubmit = () => {
        setLoader(true)
        if (props.walletTipsId) {
            let data = {
                question: updatedWalletTipsInfo.question,
                answer: editorState
            }
            UpdateApiServices.updateWalletTips(data, props.walletTipsId)
                .then((res) => {
                    if (res.status === 401) {
                        InformationToast("Session has been expired. Please login again !!!");
                        localStorage.clear();
                        authContext.setLoggedIn(false)
                        navigate("/");
                    } else {
                        props.setAddWalletTipsModal(true);
                        SuccessToast("Data updated successfully");
                    }
                })
                .catch((err) => {
                    console.error("error while updating walletTips", err)
                    ErrorToast("Something went wrong while updating data");
                })
                .finally(() => setLoader(false));
        } else {
            let data = {
                question: walletTipsData.question,
                answer: editorState
            }
            PostApiServices.AddWalletTips(data)
                .then((res) => {
                    if (res.status === 401) {
                        InformationToast("Session has been expired. Please login again !!!");
                        localStorage.clear();
                        authContext.setLoggedIn(false)
                        navigate("/");
                    } else {
                        SuccessToast("Data added successfully");
                        props.setAddWalletTipsModal(true)
                    }
                })
                .catch((err) => {
                    console.error("error while Adding walletTips", err);
                    ErrorToast("Something went wrong while adding data")
                })
                .finally(() => setLoader(false));
        }
    }
    useEffect(() => {
        if (props.walletTipsId) {
            GetApiServices.getWalletTipsById(props.walletTipsId).then((res) => {
                setWalletTipsData(res.data)
                setEditorState(res.data.answer)
                setCount(res.data.question.length)
            })
        }
    }, []);

    return (
        <div>
            <Modal
                show={props.addWalletTipsModal}
                onHide={() => props.setAddWalletTipsModal(true)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.actionType === "Add_Data" ? 'Add' : props.actionType === "Edit_Data" ? "Edit" : "View"} Wallet Tips</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={modelRef}>
                    {validation === false && (
                        <div class="alert alert-danger fw-bold" role="alert">
                            Please fill out all the fields !!!
                        </div>
                    )}
                    <form>
                        <div className={count >= 150 ? "mb-2" : "mb-4"}>
                            <label htmlFor="title" className="form-label mb-1">
                                Question
                            </label>
                            <textarea
                                className="form-control"
                                name="question"
                                value={walletTipsData.question}
                                onChange={handleInputs}
                                cols={3}
                                disabled={props.actionType === "View_Data"}
                            ></textarea>
                            {count >= 150 &&
                                <span style={{ fontSize: '0.9em', color: count == 150 ? '#58151c' : '#6c757d', fontWeight: 'bold' }}>
                                    Limit exceeded! Max 150 characters allowed.
                                </span>}
                            <span style={{ position: 'absolute', marginTop: '3px', right: '17px', fontSize: '0.9em', color: '#6c757d' }}>
                                {count}/150
                            </span>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="title" className="form-label mb-1">
                                Answer
                            </label>
                            <TextEditor
                                value={editorState}
                                onTextEditorChange={onTextEditorChange}
                                readOnly={(props.actionType === "View_Data") ? true : false}
                                actionType={props.actionType} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className={props.actionType === "View_Data" ? 'd-none' : ''}>
                    <Button
                        variant="primary"
                        onClick={() => datavalidation()}
                        disabled={loader}
                    >
                        <div className="d-flex align-center">
                            {loader ?
                                <>
                                    <div className="center"><div className="loader"></div></div>
                                </>
                                : ""}
                            <p className="d-inline mx-2 my-0">
                                {props.actionType === "Edit_Data" ? "Update" : "Add"}
                            </p>
                        </div>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddWalletTipsModal;