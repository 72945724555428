import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../popupModals/DeleteModal";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData,capitalizeFirstLetterOfWords } from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import AddUserModal from "../../popupModals/AddUserModal";
import usePutDataServices from "../../hooks/usePutDataServices";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import Loader from "../common/Loader";

const UserListing = () => {
  const GetApiServices = useGetDataServices();
  const DeleteApiServices = useDeleteDataServices();
  const UpdateApiServices=usePutDataServices();
  const [userlist, setUserList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [disableToggle, setDisableToggle] = useState(false);
  const [addUserModal, setAddUserModal] = useState({
    visibility: false,
    type: "",
    id:""
  });
  const [deleteModal, setDeleteModal] = useState({
    visibility: false,
    deleteItem: "",
    id:""
  });

  const fetchUserData = ()=>{
    fetchData(GetApiServices.getUsersList(),setUserList,setLoader);
  }

  const handleStatus = (data) => {
    setDisableToggle(true)
    let status = (data.status === "ACTIVE") ? "INACTIVE" : "ACTIVE";
    UpdateApiServices.updateUserStatus(data.id,status).then((res)=>{
        fetchUserData();
        SuccessToast("Status updated successfully");
    }).catch((err)=>{
      ErrorToast("Something went Wrong while updating status");
    })
    .finally(()=>setDisableToggle(false))
  };

  useEffect(() => {
      fetchUserData();
  }, [addUserModal, deleteModal]);

  return (
    <>
      <div className="container-fluid jumbotron shadow-sm">
        <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
          <div className="col ps-1">
            <h4 className="text-primary fw-bold">Users</h4>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
            <table class="table table-fit  align-middle">
              <thead className="table-light sticky-position table-header-height">
                <tr className="text-center table-header-font align-middle">
                  <th scope="col" className="text-center">First Name</th>
                  <th scope="col" className="text-center">Last Name</th>
                  <th scope="col" className="col-3">Email</th>
                  <th scope="col" className="text-center">Role</th>
                  <th scope="col" className="text-center">Zip code</th>
                  <th scope="col" className="text-center">Status</th>
                </tr>
              </thead>
              <tbody className="">
                {loader ? (
                  <tr className=" my-5 text-center">
                    <th colSpan="6" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) :
                userlist.length ? (
                  userlist.map((curVal, index) => (
                    <tr key={index}>
                      <td className="text-center">{curVal.firstName} </td>
                      <td className="text-center">{curVal.lastName} </td>
                      <td className="text-center">{curVal.email}</td>
                      <td className="text-center">{capitalizeFirstLetterOfWords(curVal.role)}</td>
                      <td className="text-center">{curVal.zipCode}</td>
                      <td className="text-center">
                        <div className="form-switch">
                          <input
                            className="form-check-input custom-control-input"
                            type="checkbox"
                            role="switch"
                            checked={curVal.status === "ACTIVE" ? 1 : 0}
                            onChange={() => handleStatus(curVal)}
                            disabled={disableToggle}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr className=" my-5 text-center">
                      <td colSpan="6">
                        <h3>No Data Available</h3>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {addUserModal.visibility ? (
          <AddUserModal
            type={addUserModal.type}
            id={addUserModal.id}
            addUserModal={addUserModal.visibility}
            setAddUserModal={setAddUserModal}
          />
        ) : (
          ""
        )}
        {deleteModal.visibility ? (
          <ConfirmationModal
            deleteItem={deleteModal.deleteItem}
            deleteModal={deleteModal.visibility}
            setDeleteModal={setDeleteModal}
            setLoader={setLoader}
            id={deleteModal.id}
            deleteDataApi={() => DeleteApiServices.deleteUser(deleteModal.id) }
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default UserListing