import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import useGetDataServices from "../../hooks/useGetData";
import usePutDataServices from "../../hooks/usePutDataServices";
import { fetchData } from "../../utilities/helpers";
import Loader from "../common/Loader";

const JourneyGoals = () => {
    const GetApiServices = useGetDataServices();
    const [categories, setCategories] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    const fetchCategoryData =()=>{
      fetchData(
        GetApiServices.getAllCategories(),
        setCategories,
        setLoader );
    }
    
    useEffect(()=>{
      fetchCategoryData()
    },[])

    return (
        <>
         <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col ps-1">
              <h4 className="text-primary fw-bold">Journey Goals</h4>
            </div>
            
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table className="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col" className="col-9">Category</th>
                    <th scope="col" className="text-center col">Action</th>
                  </tr>
                </thead>
                <tbody className="mid_height">
                {loader ? (
                  <tr className=" my-5 text-center">
                    {" "}
                    <th colSpan="6" className="py-5">
                      <Loader />
                    </th>
                  </tr>
                ) :
                categories.length ? (
                  categories.map((curVal, index) => (
                    <tr key={index} className={(curVal.isActive && curVal.isPublish) ? "" : "d-none"}>
                      <td className="ms-2">{curVal.name} </td>
                        <td className="text-center col-5">
                          <div className="row justify-content-center">      
                            <div className="col-auto">
                              <button
                                className="btn btn-primary edit-button"
                                onClick={() => navigate(`/journey-goals/${curVal.name}/${curVal.id}`)}
                                >
                                Add Journey Goal
                              </button>
                            </div>
                          </div>
                        </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr className=" my-5 text-center">
                      <td colSpan="2">
                        <h4>No Data Available</h4>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
              </table>
            </div>
          </div>
        </div>
        </>
      );
}

export default JourneyGoals