import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast,InformationToast } from "../utilities/notification";
import { fetchData } from "../utilities/helpers";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import Select from "react-select";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const AddSurvey = (props) => {
  const modelRef = useRef();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [allCommunities, setAllCommunities] = useState([
    {
      id: "",
      name: "",
      disabled: true
    },
  ]);
  const [allCategories, setAllCategories] = useState({
    id: "",
    value: "",
  });
  const [surveyInfo, setSurveyInfo] = useState({
    name: "",
    description: "",
    audienceType: "",
    communityIds: [],
    badgeId: "",
    categoryId: "",
    scheduleType: "",
    startDate: "",
    endDate: "",
    communities: [],
  });
  const [updatedSurveyInfo, setUpdatedSurveyInfo] = useState({});
  const [allBadge,setAllCriteria] = useState([]);
  let [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();

  const handleTab = (e) => {
    if(e.key === "Tab" || e.key === "Enter"){
      e.preventDefault();
    }
  }
  const handleInputs = (e, id) => {
    const { name, value } = e.target;
    setSurveyInfo({ ...surveyInfo, [name]: typeof value == "string" ? 
    value.trimStart().replace(/\s{2,}/g, ' ') : value });
    removeValidations();
    updateInputs(name, value);
  };

  //For multi-select input
  const selectInput = (selectedValues) => {
    let selectedCommunity = [];
    selectedValues.map((community) => {
      selectedCommunity.push(community.id);
    });

    setSurveyInfo({
      ...surveyInfo,
      communityIds: selectedCommunity,
      communities: selectedValues,
    });
    updateInputs("communityIds", selectedCommunity);
    removeValidations();
  };

  const handleAddUpdateData = (id) => {
    setLoader(true);
    if (surveyInfo.audienceType === "ALL_USERS") {
      setSurveyInfo({ ...surveyInfo, communityIds: [] });
    }

    if (surveyInfo.scheduleType === "INDEFINITE") {
      delete surveyInfo.startDate;
      delete surveyInfo.endDate;
    }
    if (surveyInfo && surveyInfo.communities !== undefined) {
      delete surveyInfo?.communities;
    }
    if (updatedSurveyInfo && updatedSurveyInfo.communities !== undefined) {
      delete updatedSurveyInfo?.communities;
    }
    if (props.id) {
      UpdateApiServices.updateSurvey(props.id, updatedSurveyInfo)
        .then((res) => {
          props.setSurveyModal(false);
          res.status == 200 && SuccessToast("Survey Updated Sucessfully");
        })
        .catch((err) => {
          ErrorToast("Something went wrong while Updating data");
        })
        .finally(() => setLoader(false));
    } else {
      delete surveyInfo.communities;
      let data = {
        name: surveyInfo.name,
        description: surveyInfo.description,
        audienceType: surveyInfo.audienceType,
        communityIds: surveyInfo.communityIds,
        categoryId:surveyInfo.categoryId ? surveyInfo.categoryId : undefined,
        badgeId:surveyInfo.badgeId? surveyInfo.badgeId : undefined,
        scheduleType: surveyInfo.scheduleType,
        startDate: surveyInfo.startDate,
        endDate: surveyInfo.endDate,
        communities: surveyInfo.communities,
      }
      PostApiServices.AddSurvey(data)
        .then((res) => {
          props.setSurveyModal(false);
          if(res.status == 201){
            SuccessToast("Survey Added Sucessfully");
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{
            ErrorToast("Something went wrong while creating QR Code !!!");
          }
        })
        .catch((err) => {
          ErrorToast("Something went wrong while Adding data");
          console.error("Survey Data error : ", err);
        })
        .finally(() => setLoader(false));
    }
  };

  const updateInputs = (...data) => {
    if (!updatedSurveyInfo.communityIds) {
      setUpdatedSurveyInfo({
        ...updatedSurveyInfo,
        [data[0]]: data[1],
        communityIds: [],
      });
    } else {
      setUpdatedSurveyInfo({ ...updatedSurveyInfo, [data[0]]: data[1] });
    }
    removeValidations();
  };

  const formValidation = () => {

    for (const key of Object.keys(surveyInfo)) {
      if (surveyInfo[key] === "") {
        if (key === "categoryId" && surveyInfo.audienceType === "ALL_USERS") {
          continue;
        }
        if ( key != "status" && key != "badgeId") {
          invalidFields.push(key);
          setValidation(false);
          modelRef.current.scrollTop = 0;
        }
      }
    }
    if (
      surveyInfo.audienceType !== "ALL_USERS" &&
      !surveyInfo.communityIds.length
    ) {
      invalidFields.push("communityIds");
      setValidation(false);
      modelRef.current.scrollTop = 0;
    }

    if (surveyInfo.scheduleType === "INDEFINITE") {
      invalidFields = invalidFields.filter(
        (field, index) => field !== "startDate" && field !== "endDate"
      );
      delete surveyInfo.startDate;
      delete surveyInfo.endDate;
    }
    if (!invalidFields.length) {
      setValidation(true);
    }
    if (validation && !invalidFields.length) {
      handleAddUpdateData();
    }
  };

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };

  const handleCriteriaOptions = (categoryId) =>{
    GetApiServices.getBadgeByCategoryId(categoryId).then((res)=>{
      setAllCriteria(res.data)
    })
  }

  useEffect(() => {
    Object.preventExtensions(surveyInfo);
    Object.seal(surveyInfo);
    Object.preventExtensions(allCommunities);
    Object.preventExtensions(allCategories);

    fetchData(GetApiServices.getAllCategories(), setAllCategories, setLoader);

    GetApiServices.getCommunityProfiles()
      .then((res) => {
        const newCommunities = res.data.map((community) => ({
          name: community.name,
          id: community.id,
        }));
        setAllCommunities((prevCommunities) => [
          ...allCommunities,
          ...newCommunities,
        ]);
      })
      .catch((err) => console.error("Error while fetching data", err));

    if (props.id) {
      GetApiServices.getSurveyById(props.id)
        .then((res) => setSurveyInfo(res.data))
        .catch((err) => console.error("Error while fetching data", err));
    }
  }, []);

  return (
    <div>
      <Modal
        show={props.surveyModal}
        onHide={() => props.setSurveyModal(true)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{props.type === "Edit_Data" ? 'Edit' : props.type === "Add_Data"  ?'Add' : 'View'} Survey Screen Setup</h5>
        </Modal.Header>
        <Modal.Body ref={modelRef} className="modal-height">
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                disabled={props.type === "View_Data"}
                value={surveyInfo.name}
                onChange={(e)=>{
                  const { value } = e.target;
                  const cleanedValue = value.trimStart().replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s{2,}/g, ' ');
                  setSurveyInfo({ ...surveyInfo, name: cleanedValue });
                  removeValidations();
                  updateInputs('name',cleanedValue)
                }}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Description
              </label>
              <textarea
                class="form-control"
                name="description"
                rows="3"
                value={surveyInfo.description}
                onChange={handleInputs}
                disabled={props.type === "View_Data"}>
                </textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-3">
                Audience:
              </label>
              <div className="control-group d-flex">
                <label className="control control--checkbox me-5">
                  All Users
                  <input
                    type="radio"
                    value="ALL_USERS"
                    name="audienceType"
                    onChange={handleInputs}
                    disabled={props.type === "View_Data"}
                    checked={surveyInfo.audienceType === "ALL_USERS"}
                  />
                  <div className="control__indicator"></div>
                </label>
                <label className="control control--checkbox">
                  Community
                  <input
                    type="radio"
                    value="SELECT_COMMUNITY"
                    checked={surveyInfo.audienceType === "SELECT_COMMUNITY"}
                    name="audienceType"
                    onChange={handleInputs}
                    disabled={props.type === "View_Data"}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
            </div>
            <div
              className={`${
                surveyInfo.audienceType === "ALL_USERS" ? "d-none" : "mb-4"
              } `}
            >
              <label htmlFor="title" className="form-label">
                Community
              </label>
              <Select
                isMulti
                value={surveyInfo.communities}
                onKeyDown={handleTab}
                onChange={selectInput}
                disabled={props.type === "View_Data"}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                options={allCommunities}
                closeMenuOnSelect={false}
                isClearable={false}
                isDisabled={surveyInfo.audienceType === "ALL_USERS"}
                isOptionDisabled={(option) => option.disabled}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Category
              </label>
              <select
                className="form-select custom-select"
                name="categoryId"
                value={surveyInfo.categoryId !== '' ? surveyInfo.categoryId : null}
                onChange={(e)=>{
                  let val = e.target.value
                  setSurveyInfo({...surveyInfo, categoryId : val})
                  handleCriteriaOptions(val)
                  removeValidations();
                  updateInputs('categoryId',val)
                }}
                disabled={props.type === "View_Data"}
              >
                <option value="" disabled selected>
                  {" "}
                  Select Category
                </option>
                {allCategories.length > 0 &&
                  allCategories.map((val) => (
                    <option
                      value={val.id}
                      className={val.isActive && val.isPublish ? "" : "d-none"}
                    >
                      {val.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-4">
            <label htmlFor="title" className="form-label">
                Badge
              </label>
              <select
                className="form-select custom-select"
                name="badgeId"
                // value={surveyInfo.badgeId}
                value={surveyInfo.badgeId && surveyInfo.badgeId !== '' ? surveyInfo.badgeId : null}
                onChange={handleInputs}
                disabled={props.type === "View_Data"}
              >
                <option value="" disabled selected>
                  {" "}
                  Select Badge
                </option>
                {allBadge.length > 0 &&
                  allBadge.map((val) => (
                    <option
                      value={val.id}
                      // className={val.isActive && val.isPublish ? "" : "d-none"}
                    >
                      {val.title}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="title" className="form-label mb-3">
                Schedule:
              </label>
              <div className="control-group d-flex">
                <label className="control control--checkbox me-5">
                  Date
                  <input
                    type="radio"
                    name="scheduleType"
                    value="DATE"
                    checked={surveyInfo.scheduleType === "DATE"}
                    onChange={handleInputs}
                    disabled={props.type === "View_Data"}
                  />
                  <div className="control__indicator"></div>
                </label>
                <label className="control control--checkbox">
                  Indefinite
                  <input
                    type="radio"
                    name="scheduleType"
                    value="INDEFINITE"
                    checked={surveyInfo.scheduleType === "INDEFINITE"}
                    onChange={handleInputs}
                    disabled={props.type === "View_Data"}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
            </div>
            <div
              className={`${
                surveyInfo.scheduleType === "INDEFINITE" ? "d-none" : "mb-4"
              } `}
            >
              <label htmlFor="title" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control cal"
                name="startDate"
                onClick={(e)=>e.target.showPicker()}
                onKeyDown={(e)=>e.target.showPicker()}
                min={new Date().toISOString().split("T")[0]}
                value={surveyInfo?.startDate?.split("T")[0]}
                onChange={(e) => {
                  setSurveyInfo({
                    ...surveyInfo,
                    startDate: `${e.target.value}T00:00:00.000Z`,
                  });
                  removeValidations()
                  updateInputs("startDate", `${e.target.value}T00:00:00.000Z`);
                }}
                disabled={surveyInfo.scheduleType === "INDEFINITE" || props.type === "View_Data"}
              />
            </div>
            <div
              className={`${
                surveyInfo.scheduleType === "INDEFINITE" ? "d-none" : "mb-4"
              } `}
            >
              <label htmlFor="title" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control datepicker"
                onClick={(e)=>e.target.showPicker()}
                onKeyDown={(e)=>e.target.showPicker()}
                name="endDate"
                value={surveyInfo?.endDate?.split("T")[0]}
                min={
                  surveyInfo?.startDate
                    ? surveyInfo?.startDate.split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
                // readOnly={!surveyInfo?.startDate}
                onChange={(e) => {
                  setSurveyInfo({
                    ...surveyInfo,
                    endDate: `${e.target.value}T00:00:00.000Z`,
                  });
                  removeValidations();
                  updateInputs("endDate", `${e.target.value}T00:00:00.000Z`);
                }}
                disabled={surveyInfo.scheduleType === "INDEFINITE" || props.type === "View_Data"}
              />
              {/* <DatePicker 
              onChange={(date2) =>{ 
              let formatEndDateString = new Date(date2)?.toISOString()
              setSurveyInfo({ ...surveyInfo, endDate:formatEndDateString})
              updateInputs("endDate", formatEndDateString);}} 
              clearIcon={false} 
              value={surveyInfo.endDate}
              minDate={surveyInfo.startDate ? new Date(surveyInfo.startDate) : new Date()}
              format="yyyy/MM/dd"
              className="col-12"
              /> */}
            </div>
          </form>
        </Modal.Body>
        {!(props.type === "View_Data") && (
          <Modal.Footer>
            {/* <Button
              variant="primary"
              disabled={loader}
              onClick={formValidation}
            >
              {loader ? <Loader /> : ""}
              <p className="d-inline mx-2">
                {props.type === "Add_Data" ? "Add" : "Update"}
              </p>
            </Button> */}
            <Button
          variant="primary"
          disabled={loader}
          onClick={formValidation}
         >
         <div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
        </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default AddSurvey;
