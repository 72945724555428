import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast, InformationToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import { validateToken } from "../utilities/helpers";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const QuestionsModal = (props) => {
    const modelRef = useRef();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const imageServices = useImageServices();
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [questionsInfo, setQuestionsInfo] = useState({
      question: "",
      category: "",
      categoryId : "",
      answerType: "",
      buttonText: "",
      tokenAmount: 0,
      status: "",
      optionValue:['']
    });
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
  
    const handleenter = (e)=>{
      if(e.key === "Enter"){
        e.preventDefault();
      }
    }
    const handleInputs = (e, id) => {
      const { name, value } = e.target;
      setQuestionsInfo({ ...questionsInfo, 
        [name]: typeof value === "string" ? 
        value.trimStart().replace(/[^a-z\s]/ig, '').replace(/\s{2,}/g, ' ') : value
      });
      removeValidations();
    };

    //purpose : Add options inputs dynamically
    const addOptionField = (e) => {
        setQuestionsInfo((prevState) => ({...prevState,optionValue:[...prevState.optionValue ,''] }))
        e.preventDefault();
        removeValidations()
    } 

    //Purpoe :  Delete option dynamically
    const deleteOption = (optionId, e) => {
      e.preventDefault();
      const filterOption = questionsInfo?.optionValue?.filter((option, id) => id !== optionId)
      setQuestionsInfo({...questionsInfo, optionValue:filterOption})
      removeValidations();
    }

    const handleAddUpdateData =(id)=>{
      let data = {
        "order": 0,
        "question": questionsInfo.question,
        "answerType": questionsInfo.answerType,
        "options": questionsInfo.optionValue,
        "categoryId": questionsInfo.categoryId,
        "buttonText": questionsInfo.buttonText,
        "tokenAmount": questionsInfo.tokenAmount
      }
      if(props.id){
        UpdateApiServices.updateOnboardingQuestions(props.id, data).then((res)=>{
          props.setQuestionsModal(false)
          if(res.status == 200){
            SuccessToast("Question Updated Sucessfully");
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{ErrorToast("Something went wrong !!");}
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Updating data')
        })
        .finally(() => setLoader(false))
      }else{
        PostApiServices.AddOnboardingQuestions(data).then((res)=>{
          props.setQuestionsModal(false)
          if(res.status == 201){SuccessToast("Question Added Sucessfully")}
          else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{ErrorToast("Something went wrong !!!");}
        })
        .catch((err)=>{
          ErrorToast('Something went wrong while Adding data')
        })
        .finally(() => setLoader(false))
      }
    }

    const formValidation = () => {
      setLoader(true)
      for (const key of Object.keys(questionsInfo)) {
        if (questionsInfo[key] === "") {
          // setInvalidFields((prevVal) => [...prevVal, ...invalidFields, key]);
          if ((key != 'category') && (key != 'status')){
            invalidFields.push(key)
            setValidation(false);
            setLoader(false);
            modelRef.current.scrollTop = 0;
          }
        }
      }
      if(questionsInfo.optionValue){
        for(let i=0;i<questionsInfo.optionValue.length; i++){
          if(questionsInfo.optionValue[i].trim() === ""){
            invalidFields.push(`optionValue${i}`);
            setValidation(false);
            setLoader(false);
            modelRef.current.scrollTop = 0;
          }
        }
      }
      if (validation && !invalidFields.length) {
        handleAddUpdateData();
      }
    };

    const removeValidations = () => {
      setValidation(true);
      setInvalidFields([]);
    };

    useEffect(()=>{
      GetApiServices.getAllCategories().then((res)=>setCategories(res.data))
      if(props.type === "Edit_Data" || props.type === "View_Data"){
      GetApiServices.getOnboardingQuestionsById(props.id).then((res)=>{
        const data = res.data;
        setQuestionsInfo({
          question: data.question,
          category: data.category.name,
          categoryId : data.categoryId,
          answerType: data.answerType,
          buttonText: data.buttonText,
          tokenAmount: data.tokenAmount,
          status: data.isActive,
          optionValue:data.options
        })
      }).catch((err)=>{
        ErrorToast('Something went wrong while getting data')
      })
    }
    },[])


  return (
    <div>
    <Modal
      show={props.questionsModal}
      onHide={() => props.setQuestionsModal(true)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
      <h5 className="modal-title">{props.type === "Edit_Data" ? 'Edit Onboarding Question' : props.type === "Add_Data"  ?'Add Onboarding Question' : 'View Onboarding Questions'}</h5>
      </Modal.Header>
      <Modal.Body ref={modelRef} className="modal-height">
        {validation === false && (
          <div class="alert alert-danger fw-bold" role="alert">
            Please fill out all the fields !!!
          </div>
        )}
        <form>
          <div className="mb-4">
            <label htmlFor="title" className="form-label">
              Question
            </label>
            <input
              type="text"
              className="form-control"
              id="question"
              name="question"
              disabled = {props.type === "View_Data"}
              value={questionsInfo.question}
              onKeyDown={handleenter}
              onChange={(e)=>{
                const { value } = e.target;
                const cleanedValue = value.trimStart().replace(/\s{2,}/g, ' ');
                setQuestionsInfo({ ...questionsInfo, question: cleanedValue });
                removeValidations();
              }}
            />
          </div>
          <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Category
              </label>
              <select className="form-select custom-select" 
              name="categoryId" 
              value={questionsInfo.categoryId} 
              disabled = {props.type === "View_Data"}
              onChange={(e)=>{
                setQuestionsInfo({...questionsInfo,categoryId : e.target.value})
                removeValidations()
              }}>
                  <option value="" disabled selected> Select Category</option>
                  {categories.length > 0 && 
                  categories.map((val)=>(
                      <option value={val.id} className={(val.isActive && val.isPublish)? '' : 'd-none'}>{val.name}</option>
                    ))
                  }
              </select>
          </div>
          <div className="mb-4">
            <label htmlFor="title" className="form-label">
              Button Text
            </label>
            <input
              type="text"
              className="form-control"
              id="buttonText"
              name="buttonText"
              disabled = {props.type === "View_Data"}
              value={questionsInfo.buttonText}
              onKeyDown={handleenter}
              onChange={(e) => { setQuestionsInfo({ ...questionsInfo, buttonText: e.target.value}); removeValidations()}}
            />
          </div>
          <div className="mb-4">
              <label htmlFor="title" className="form-label">
                 Answer Type
              </label>
              <select
                className="form-select" value={questionsInfo.answerType}
                onChange={handleInputs}
                disabled = {props.type === "View_Data"}
                name="answerType" id = "answerType">
                  <option value="" disabled selected>Select Answer Type</option>
                  <option value="DROPDOWN">Dropdown</option>
                  <option value="CHECKBOX">Checkbox</option>
                  <option value="RADIO">Radio input</option>
              </select>
          </div>
          {questionsInfo?.optionValue?.map((curOption,index)=>(
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Option {index+1}
              </label>
              <div className="row">
                <div className="col-12 col-md-10">
                <input
                  type="text"
                  className="form-control"
                  id={`optionValue${index}`}
                  name={`optionValue${index}`}
                  onKeyDown={handleenter}
                  disabled = {props.type === "View_Data"}
                value={curOption.trimStart().replace(/\s{2,}/g, " ")}
                  onChange={(e) => {
                    let optionsList = questionsInfo?.optionValue;
                    optionsList[index] = e.target.value
                    setQuestionsInfo({...questionsInfo, optionValue: optionsList})
                    removeValidations();
                  }}
                />
                </div>
                <button  className="btn btn-outline-secondary my-2 my-md-0 col-12 col-md-2" onClick={(e)=>deleteOption(index, e)} disabled={questionsInfo?.optionValue?.length===1}>Delete</button>
                </div>
            </div>
          ))}
          {!(props.type === "View_Data") && <button onClick={addOptionField} className="btn btn-outline-primary mb-4">Add option</button>}
          <div className=" mb-4">
            <label htmlFor="title" className="form-label">
             Token Amount
            </label>
            <input
              type="text"
              className="form-control"
              id="tokenAmount"
              name="tokenAmount"
              value={questionsInfo.tokenAmount}
              onKeyDown={handleenter}
              disabled = {props.type === "View_Data"}
              onChange={(e) => {
                let tokenValue = e.target.value;
                validateToken(tokenValue, setQuestionsInfo, questionsInfo);
                  removeValidations();
              }}
            />
          </div>
        </form>
      </Modal.Body>
      {!(props.type === "View_Data") &&
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={loader}
          onClick={formValidation}
        ><div className="d-flex align-center">
          {loader ? 
          <>
          <div className="center"><div className="loader"></div></div>
          </>
           : ""}
          <p className="d-inline mx-2 my-0">
            {props.type === "Add_Data" ? "Add" : "Update"}
          </p>
          </div>
        </Button>
      </Modal.Footer>}
    </Modal>
  </div>
  )
}

export default QuestionsModal