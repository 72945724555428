import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate } from "react-router";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";
import CommunityModal from "../../popupModals/CommunityModal";
import CommunityPostModal from "../../popupModals/CommunityPostModal";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";

const CommunityProfile = () => {
    const [loader, setLoader] = useState(true);
    const GetApiServices = useGetDataServices();
    const DeleteApiServices = useDeleteDataServices();
    const UpdateApiServices = usePutDataServices();
    const navigate = useNavigate(); 
    const [communityProfiles,setCommunityProfiles] = useState([]);
    const [communityModal, setCommunityModal] = useState({
        visibility: false,
        type: "",
        id:""
      });
    const [communityPostModal, setCommunityPostModal] = useState({
      visibility: false,
      id:""
    });
    
    const handleAddEditData = (type,id) =>{
        setCommunityModal({
          ...communityModal,
          visibility: true,
          type,
          id
        });
      }

    const fetchCommunityProfiles = () =>{
        GetApiServices.getCommunityProfiles().then((res)=>{
          (res.status === 200) && setCommunityProfiles(res.data)
          setLoader(false)
        }).catch((err)=>{
          ErrorToast('Something went wrong while getting data')
          setLoader(false)
        })
    }

    const handlePublish = (id) =>{
      UpdateApiServices.publishCommuinityProfile(id).then((res)=>{
        SuccessToast("Community Published Successfully");
        fetchCommunityProfiles();
      }).catch((err)=>{
        ErrorToast('Something Went Wrong');
      });
    };

    const handleStatus =(data)=>{
      let status = (data.isActive == true) ? false : true ;
      UpdateApiServices.updateCommuinityProfileStatus(data.id,status).then((res)=>{
        fetchCommunityProfiles();
      }).catch((err)=>{
        // fetchCommunityProfiles();
        ErrorToast('Something Went Wrong');
      });
    }

    useEffect(()=>{
      fetchCommunityProfiles();
    },[communityModal])
    

  return (
    <div className="container-fluid jumbotron shadow-sm">
    <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
      <div className="col ps-1">
        <h4 className="text-primary fw-bold">Community Profile</h4>
      </div>
      <div className="col-auto pe-1">
        <button
          className="btn btn-primary add-button col-auto"
          onClick={() => handleAddEditData("Add_Data")}
        >
          Add Community
        </button>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
        <table className="table table-fit  align-middle">
          <thead className="table-light sticky-position table-header-height">
            <tr className=" table-header-font align-middle">
              <th scope="col" className=" col-1">Name</th>
              <th scope="col" className=" col-3">Description</th>
              <th scope="col" className="text-center">Zip code</th>
              <th scope="col" className="text-center">Status</th>
              <th scope="col" className="text-center">Profile Photo</th>
              <th scope="col" className="text-center">Cover Photo</th>
              {/* <th scope="col" className="text-center col-2">Status</th> */}
              <th scope="col" className="text-center">Publish</th>
              <th scope="col" className="text-center"></th>
            </tr>
          </thead>
          <tbody className="">
            {loader ? (
              <tr className=" my-5 text-center">
                {" "}
                <th colSpan="9" className="py-5">
                  <Loader />
                </th>
              </tr>
            ) : 
            communityProfiles.length ? (communityProfiles.map((val)=>{
              return (
                <tr>
                  <td className="col-1">{val?.name}</td>
                  <td className="col-2 text-justify" style={{fontFamily: 'Montserrat'}} dangerouslySetInnerHTML={{ __html: val?.description }}></td>
                  <td className="text-center">
                  {['left'].map((left) => (
                      <OverlayTrigger
                        key={left}
                        placement={left}
                        overlay={
                          <Tooltip id={`tooltip-left`}>{
                              val?.communityZipCode.map((zipcode)=> <p>{`${zipcode.zipCode},`}</p>)
                            } </Tooltip>
                    }
                  >
                  <p>{val?.communityZipCode[0].zipCode}</p>
                  </OverlayTrigger> 
                ))}
                   </td>
                  
                  <td className="col-1 text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" 
                            disabled={!val?.isPublish} onClick={() => handleStatus(val)} 
                            checked={val?.isActive}/>
                          </div>
                        </td>
                  <td className="col-2 text-center">
                    <Link to={val?.imageUrl} target="_blank">
                      <img src={val?.imageUrl} width="70" height="70"/>
                    </Link>
                  </td>
                  <td className="col text-center">
                    <Link to={val?.coverImageUrl} target="_blank">
                      <img src={val?.coverImageUrl} width="70" height="70"/>
                    </Link>
                  </td>
                  <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={val?.isPublish}
                            checked={val?.isPublish} onClick={()=>handlePublish(val?.id)}/>
                          </div>
                  </td>
                  <td className="text-center">
                      <div className="btn-group">
                      <button type="button" className="dot"  data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                      </button>
                      <ul className="dropdown-menu py-0">
                      <li><a className="dropdown-item" onClick={() => setCommunityPostModal({visibility:true, id:val?.id})}><i className="fa-solid fa-plus"></i> Add Post</a></li>
                      <li>
                          <a className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${val?.id}`) }><i className="fa-solid fa-eye"></i> View</a>
                      </li>
                      <li>
                          <a className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${val?.id}`) }><i className="fa-solid fa-pen-to-square"></i> Edit</a>
                      </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              )
            })) : (
              <>
                <tr className=" my-5 text-center">
                <th colSpan={7} className="py-5">
                  <h4 className="text-muted">No Data Available</h4>
                </th>
                </tr>
              </>)
            }
          </tbody>
        </table>
      </div>
    </div>
     {communityPostModal.visibility ? (
      <CommunityPostModal
        communityPostModal={communityPostModal.visibility}
        communityID={communityPostModal.id}
        setCommunityPostModal={setCommunityPostModal}
      />
    ) : (
      ""
    )}
     {communityModal.visibility ? (
      <CommunityModal
        type={communityModal.type}
        id={communityModal.id}
        communityModal={communityModal.visibility}
        setCommunityModal={setCommunityModal}
      />
    ) : (
      ""
    )}
    {/*{deleteModal.visibility ? (
      <ConfirmationModal
        // deleteItem={deleteModal.deleteItem}
        // deleteModal={deleteModal.visibility}
        // setDeleteModal={setDeleteModal}
        // setLoader={setLoader}
        // id={deleteModal.id}
        // deleteDataApi={() => DeleteApiServices.deleteOnboardingScreen(deleteModal.id)}
      />
    ) : (
      ""
    )} */}
  </div>
  )
}

export default CommunityProfile