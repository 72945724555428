import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast, InformationToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import { TagsInput } from "react-tag-input-component";
import { convertHtmlToText } from "../utilities/helpers";
import TextEditor from "../components/common/TextEditor";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const CommunityModal = (props) => {
  const modelRef = useRef();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [loader, setLoader] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();
  const [zipcode, setZipcode] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [profileData, setProfileData] = useState({
    name: '',
    description: '',
    communityZipCode: [],
    imageUrl: '',
    coverImageUrl: '',
    communityBusiness: [
      {
        "name": "",
        "imageUrl": "",
        "website": ""
      }
    ]
  });

  const handleInputs = (e, id) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value.trimStart().replace(/\s{2,}/g, ' ') });
    removeValidations();
  };

  const onTextEditorChange = (e) => {
    setEditorState(e)
    removeValidations();
  }

  //Multiple zipcode functionality
  const handleZipcodeChange = (newZipcode) => {
    setProfileData({ ...profileData, communityZipCode: newZipcode });
    removeValidations();
  }

  // for adding extra business details
  const addBusinessFields = (e) => {
    setProfileData((prevState) => ({
      ...prevState,
      communityBusiness: [...prevState.communityBusiness, {
        "name": "",
        "imageUrl": "",
        "website": ""
      }]
    }))
    e.preventDefault();
  }

  // for removing extra business details
  const removeBusinessFields = (e, index) => {
    let businessDetails = profileData.communityBusiness;
    businessDetails.splice(index, 1)
    setProfileData((prevState) => ({
      ...prevState,
      communityBusiness: businessDetails
    }))
  }

  const handleBusinessInputs = (e, index) => {
    const { name, value } = e.target;
    let updatedObject = profileData.communityBusiness[index];
    let updatedArray = profileData.communityBusiness;
    updatedObject[name] = value.trimStart().replace(/\s{2,}/g, ' ');
    updatedArray[index] = updatedObject;
    setProfileData((prevState) => ({
      ...prevState,
      communityBusiness: updatedArray
    }));
    removeValidations();
  }

  const formValidations = () => {
    setInvalidFields([])
    Object.keys(profileData).forEach((key) => {
      if (profileData["description"] && (profileData[key] === "" || !profileData[key].length)) {
          invalidFields.push(key);
          setValidation(false);
          modelRef.current.scrollTop = 0;
      }
    })

    //Below is validation for 'description' field when it changes to Text-editor instead of textArea
      let descriptionField = convertHtmlToText(editorState);
      if (descriptionField === "") {
        setValidation(false);
        invalidFields.push("description");
        modelRef.current.scrollTop = 0;
      }

    profileData.communityBusiness.map((business, index) => {
      if (business.name === "" || business.imageUrl === "" || business.website === "") {
        invalidFields.push(`business_${index}`);
        modelRef.current.scrollTop = 0;
        setValidation(false);
      }
    });

    if (validation && !invalidFields.length) {
      handleAddUpdateData()
    }
  }

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  }

  const applyMontserratFontStyle = (content) => {
    return `<span style="font-family: 'Montserrat';">${content}</span>`;
  }
  const handleAddUpdateData = () => {
    setLoader(true)
    const montserratStyledContent = applyMontserratFontStyle(editorState);
    let data = {
      name: profileData.name,
      description: montserratStyledContent,
      // description: profileData.description,
      communityZipCode: profileData.communityZipCode,
      imageUrl: profileData.imageUrl,
      coverImageUrl: profileData.coverImageUrl,
      communityBusiness: profileData.communityBusiness
    }
    if (props.type == 'Add_Data') {
      PostApiServices.AddCommunityProfile(data).then((res) => {
        props.setCommunityModal(false)
        if (res.status == 201) {
          SuccessToast("Community Added Sucessfully")
        } else if (res.status === 401) {
          InformationToast("Session has been expired. Please login again !!!");
          localStorage.clear();
          authContext.setLoggedIn(false)
          navigate("/");
        } else {
          setLoader(false)
          ErrorToast("Something went wrong  !!!");
        }
      }).catch((err) => {
        setLoader(false)
        ErrorToast('Something went wrong while Adding data')
      })
    } else if (props.type == 'Edit_Data') {
      UpdateApiServices.updateCommuinityProfile(props.id, data).then((res) => {
        props.setCommunityModal(false)
        if (res.status == 200) {
          SuccessToast("Community Updated Sucessfully")
        } else if (res.status === 401) {
          InformationToast("Session has been expired. Please login again !!!");
          localStorage.clear();
          authContext.setLoggedIn(false)
          navigate("/");
        } else {
          ErrorToast("Something went wrong  !!!");
        }
        setLoader(false)
      }).catch((err) => {
        ErrorToast('Something went wrong while Updating data');
        setLoader(false)
      })
    }
  }

  const handleImageUpload = (file, field) => {
    setLoader(true);
    if (file?.type.startsWith("image/")) {
      PostApiServices.uploadImgToServer({ file: file })
        .then(async (res) => {
          if (field == "imageUrl") {
            setProfileData({ ...profileData, imageUrl: res.data.Location })
          }
          else if (field == "coverImageUrl") {
            setProfileData({ ...profileData, coverImageUrl: res.data.Location })
          }
        })
        .catch((err) => ErrorToast("Problem while uploading image"))
        .finally(() => setLoader(false));
    } else {
      file && InformationToast("Please choose only image file to be uploaded.");
      if (field === "imageUrl") {
        setProfileData({ ...profileData, imageUrl: "" })
      }
      else {
        setProfileData({ ...profileData, coverImageUrl: "" })
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (props.id) {
      // fetchData(GetApiServices.getCommunityProfilesById(props.id),setProfileData,setLoader);

      //Destructuring zipcode object
      GetApiServices.getCommunityProfilesById(props.id)
        .then((res) => {
          const allZipcodes = res.data.communityZipCode.map((val) => val.zipCode)
          setProfileData({
            name: res.data.name,
            description: res.data.description,
            communityZipCode: allZipcodes,
            imageUrl: res.data.imageUrl,
            coverImageUrl: res.data.coverImageUrl,
            communityBusiness: res.data.communityBusiness
          })
          setEditorState(res.data.description)
        })
    }
  }, [])

  return (
    <div>
      <Modal
        show={props.communityModal}
        onHide={() => props.setCommunityModal(false)}
        backdrop="static"
        keyboard={false}
        className="add_community_modal"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{props.type === "Edit_Data" ? "Edit" : props.type === "Add_Data" ? "Add" : "View"} Community</h5>
        </Modal.Header>
        <Modal.Body className="modal-height" ref={modelRef} >
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id='name'
                name='name'
                value={profileData.name}
                onChange={handleInputs}
                disabled={props.type === "View_Data"}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Description
              </label>
              <TextEditor
                value={editorState}
                onTextEditorChange={onTextEditorChange}
                readOnly={(props.type === "View_Data") ? true : false}
                actionType={props.type} />
                {/* <textarea 
                    className="form-control" 
                    value={profileData.description}
                    onChange={handleInputs}
                    disabled = {props.type === "View_Data"}
                    name="description"
                    id = "description">
                </textarea> */}
            </div>
            <div className="row">
              <div className="col-md-4 mb-4">
                <label htmlFor="title" className="form-label">
                  Zipcode
                </label>
                <TagsInput
                  value={profileData.communityZipCode.length ? profileData.communityZipCode : []}
                  onChange={handleZipcodeChange}
                  name="communityZipCode"
                  disabled={props.type === "View_Data"}
                />
                <p className="text-sm">Press enter after entering each zipcode</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-4">
                <label htmlFor="title" className="form-label">
                  Profile Photo
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="imageUrl"
                  accept="image/png, image/jpeg"
                  name="imageUrl"
                  onChange={(e) => {
                    handleImageUpload(e.target.files[0], "imageUrl")
                  }}
                  disabled={props.type === "View_Data"}
                />
                {profileData.imageUrl ? (
                  <div>
                    <p>Image Preview : </p>
                    <div className="row ms-2">
                      {loader ? (
                        <div className="h-75 col-5 text-center">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <img
                            src={profileData?.imageUrl}
                            className=" col-5 img-fluid border"
                          />
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-4 mb-4">
                <label htmlFor="title" className="form-label">
                  Cover Photo
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  accept="image/png, image/jpeg"
                  name="imageUrl"
                  onChange={(e) => {
                    handleImageUpload(e.target.files[0], "coverImageUrl")
                  }}
                  disabled={props.type === "View_Data"}
                />
                {profileData?.coverImageUrl ? (
                  <div>
                    <p>Image Preview : </p>
                    <div className="row ms-2">
                      {loader ? (
                        <div className="h-75 col-5 text-center">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <img
                            src={profileData?.coverImageUrl}
                            className=" col-5 img-fluid border"
                          />
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>Partner Logo</th>
                      <th>Logo Preview</th>
                      <th>Business Name</th>
                      <th>Business Website</th>
                      <th width="180px" className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profileData.communityBusiness.length ? (
                      profileData.communityBusiness.map((val, index) => (
                        <tr>
                          <td valign="center" width="280">
                            <input
                              type="file"
                              className="form-control"
                              id="image"
                              accept="image/png, image/jpeg"
                              name="imageUrl"
                              disabled={props.type === "View_Data"}
                              onChange={(e) => {
                                const selectedFile = e.target.files[0];

                                if (selectedFile) {
                                  PostApiServices.uploadImgToServer({ file: selectedFile })
                                    .then(async (res) => {
                                      let obj = {
                                        target: {
                                          name: 'imageUrl',
                                          value: res.data.Location,
                                        },
                                      };
                                      handleBusinessInputs(obj, index);
                                    })
                                    .catch((err) => {
                                      ErrorToast("Problem while uploading image");
                                    });
                                } else {
                                  let obj = {
                                    target: {
                                      name: 'imageUrl',
                                      value: '',
                                    },
                                  };
                                  handleBusinessInputs(obj, index);
                                }
                              }}
                            />
                          </td>
                          <td valign="center" width="200">
                            {val?.imageUrl ? (
                              <div>
                                <div className="row ms-2">
                                  {loader ? (
                                    <div className="h-75 col-5 text-center">
                                      <Loader />
                                    </div>
                                  ) : (
                                    <>
                                      <img
                                        src={val?.imageUrl}
                                        className=" col-5 img-fluid"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id='name'
                              value={val.name}
                              onChange={(e) => handleBusinessInputs(e, index)}
                              disabled={props.type === "View_Data"}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="website"
                              id='website'
                              value={val.website}
                              onChange={(e) => handleBusinessInputs(e, index)}
                              disabled={props.type === "View_Data"}
                            />
                          </td>
                          <td className="text-center">
                            {/* <button class="btn btn-primary btn-sm edit-button me-2">Edit</button> */}
                            <button
                              class="btn btn-outline-secondary btn-sm edit-button"
                              disabled={profileData.communityBusiness.length <= 1 || props.type === "View_Data"}
                              onClick={(e) => removeBusinessFields(e, index)}>Delete</button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center">No Data Available. Click on "Add More Business" below to add data.</td>
                      </tr>
                    )
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-12">
                <button className={` btn btn-outline-primary ${props.type === "View_Data" && 'd-none'}`}
                  onClick={addBusinessFields}>Add More Business</button>
              </div>


            </div>
          </form>
        </Modal.Body>
        <Modal.Footer align="center" className={`${props.type === "View_Data" && 'd-none'}`}>
          {/* <Button variant="primary" disabled={loader}
          // onClick={()=>handleAddUpdateData()}
          onClick={()=>formValidations()}
          >
            {loader ? <Loader /> : ""}
            {props.id ? "Update" : "Add"} 
          </Button> */}
          <Button
            variant="primary"
            disabled={loader}
            onClick={() => formValidations()}
          ><div className="d-flex align-center">
              {loader ?
                <>
                  <div className="center"><div className="loader"></div></div>
                </>
                : ""}
              <p className="d-inline mx-2 my-0">
                {props.id ? "Update" : "Add"}
              </p>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CommunityModal