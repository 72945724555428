import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ErrorToast } from "../utilities/notification";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import { fetchData } from "../utilities/helpers";

const AssetViewResultModal = (props) => {
    const modelRef = useRef();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [loader, setLoader] = useState(false);
    const [validation, setValidation] = useState();
    const [assetViewResultData,setassetViewResultData]=useState([])

    const handleStatus =(data)=>{
      let status = (data.isComplete == true) ? false : true ;
      UpdateApiServices.updateAssetStatus(data.id,status).then((res)=>{
        fetchCategoryData();
      }).catch((err)=>{
        fetchCategoryData();
        ErrorToast('Something Went Wrong');
      });
    }
  

      const fetchCategoryData =()=>{
        if(props.id){
          fetchData(
            GetApiServices.getAssetsUserListById(props.id),
            setassetViewResultData,
            setLoader );
        }
      }
      useEffect(()=>{
        setLoader(true);
        fetchCategoryData()
      },[])
    return (
        <div>
          <Modal
            show={props.assetViewResultModal}
            onHide={() => props.setAssetViewResultModal(true)}
            backdrop="static"
            keyboard={false}
            className="asset-view-result"
          >
            <Modal.Header closeButton>
              <Modal.Title>User's Result</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={modelRef} className="">
              {validation === false && (
                <div class="alert alert-danger fw-bold" role="alert">
                  Please fill out all the fields !!!
                    </div>
              )}
              <form>
                <div className="col-12 col-md-12 table-responsive fixed-table"
                    style={{ overflowX: "auto" }} >
                    <table class="table table-fit  align-middle">
                        <thead className="table-light sticky-position table-header-height">
                            <tr className=" table-header-font align-middle">
                            <th scope="col-2" className="text-center">Asset Name</th>
                            <th scope="col-2" className="text-center">Token Amount</th>
                            <th scope="col-2" className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {loader ? (
                               <tr className=" my-5 text-center">
                               <th colSpan="9" className="py-5">
                                  <Loader />
                               </th>
                               </tr>
                ) : assetViewResultData.length ? (
                  assetViewResultData.map((curVal, index) => (
                  <tr key={index}>                    
                    <td className="col-2 text-center">{curVal.asset.name}</td>
                    <td className="col-2 text-center">{curVal.tokenAmount}</td>
                    <td className="text-center col-2">
                        {
                          curVal.isComplete ?
                           <button className="btn-outline-primary p-2 px-4 badge" onClick={(e)=>e.preventDefault()}>Complete</button> : 
                           <button className="btn-outline-danger" onClick={(e)=>e.preventDefault()}>Incomplete</button>
                        }
                    </td>
                  </tr>
                ))
                ) : null}
              </tbody>
                    </table>
                </div>                
              </form>
            </Modal.Body>
            
          </Modal>
        </div>
      );
}

export default AssetViewResultModal