import { ErrorToast, InformationToast } from "../utilities/notification";
import usePostDataServices from './usePostData';

const useImageServices = () => {
    const PostApiServices = usePostDataServices();

    const validateImageDimensions = async (file, setterFunction, state, loader) => {
        if (file && file?.type.startsWith("image/")) {
            loader(true)
            let img = new Image();
            img.src = URL.createObjectURL(file);
            await img.decode();
            let imgWidth = img.width;
            let imgHeight = img.height;

            if (imgWidth == 512 && imgHeight == 512) {
                PostApiServices.uploadImgToServer({ "file": file })
                    .then(async (res) => {
                        setterFunction({ ...state, imageUrl: res.data.Location })
                        await loader(false)
                    })
                    .catch((err) => {
                        setterFunction({ ...state, imageUrl: "" })
                        ErrorToast("Problem while uploading image")
                        loader(false)
                    });
            } else {
                setterFunction({ ...state, imageUrl: "" })
                ErrorToast("Image dimension should be 512 x 512.")
                await loader(false)
            }
        } else {
            setterFunction({ ...state, imageUrl: "" });
            file && InformationToast("Please choose only image file to be uploaded.");
            loader(false);
        }
    }

    return { validateImageDimensions }
}

export default useImageServices