import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useGetDataServices from "../hooks/useGetData";
import useImageServices from "../hooks/useImageServices";
import usePostDataServices from "../hooks/usePostData";
import usePutDataServices from "../hooks/usePutDataServices";
import { fetchData, validateToken } from "../utilities/helpers";
import { ErrorToast, InformationToast, SuccessToast } from "../utilities/notification";
import { useContext } from "react";
import { useNavigate } from "react-router";
import AuthContext from "../context/AuthProvider";

const SurveyQuestionModal = (props) => {
  const modelRef = useRef();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [questionsInfo, setQuestionsInfo] = useState({
    order: 0,
    question: "",
    answerType: "",
    options: [""],
    surveyId: props.surveyId,
    tokenAmount: "",
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState(true);

  const handleInputs = (e, id) => {
    const { name, value } = e.target;
    setQuestionsInfo({ ...questionsInfo, [name]: value });
    removeValidations();
  };

  const handleenter = (e)=>{
    if(e.key === "Enter"){
      e.preventDefault();
    }
  }
  
  //purpose : Add options inputs dynamically
  const addOptionField = (e) => {
    setQuestionsInfo((prevState) => ({
      ...prevState,
      options: [...prevState.options, ""],
    }));
    e.preventDefault();
  };

  //Purpose : Delete Option field dynamically
    const handleDeleteOption = (optionId, e) => {
      e.preventDefault();
      const filteredOptions = questionsInfo.options.filter(
        (option, id) => id !== optionId
      );
      setQuestionsInfo((prevState) => ({
        ...prevState,
        options: filteredOptions,
      }));
    };

  const handleAddUpdateData = (id) => {
    setLoader(true);
    let data = {
      order: 0,
      question: questionsInfo.question,
      answerType: questionsInfo.answerType,
      options: questionsInfo.options,
      surveyId: questionsInfo.surveyId,
      tokenAmount: questionsInfo.tokenAmount,
    };

    if (questionsInfo.answerType === "TEXT") {
      data.options = [""];
    }

    if (props.id) {
      UpdateApiServices.updateSurveyQuestion(props.id, data)
        .then((res) => {
          props.setSurveyQuestionModal(false);
          if(res.status == 200){
            SuccessToast("Question Updated Sucessfully");
          }else if (res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{ErrorToast("Something went wrong !!!");}
        })
        .catch((err) => {
          ErrorToast("Something went wrong while Updating data");
        })
        .finally(() => setLoader(false));
    } else {
      PostApiServices.AddSurveyQuestion(data)
        .then((res) => {
          if(res.status == 201){
            SuccessToast("Question Added Sucessfully");
          }else if(res.status === 401) {
            InformationToast("Session has been expired. Please login again !!!");
            localStorage.clear();
            authContext.setLoggedIn(false)
            navigate("/");
          }else{
            ErrorToast("Something went wrong !!!");
          }
          props.setSurveyQuestionModal(false);
        })
        .catch((err) => {
          ErrorToast("Something went wrong while Adding data");
        })
        .finally(() => setLoader(false));
    }
  };

  const formValidation = () => {
    for (const key of Object.keys(questionsInfo)) {
      if (questionsInfo[key] === "" || isNaN(questionsInfo["tokenAmount"])) {
        if (key != "category" && key != "status") {
          invalidFields.push(key);
          setValidation(false);
          modelRef.current.scrollTop = 0;
        }
      }
    }
    if (
      questionsInfo.answerType !== "TEXT" &&
      questionsInfo.options.includes("")
    ) {
      invalidFields.push("options");
      setValidation(false);
      modelRef.current.scrollTop = 0;
    }
    if (validation && !invalidFields.length) {
      handleAddUpdateData();
    }
  };

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };

  useEffect(() => {
    if (props.id) {
      fetchData(
        GetApiServices.getSurveyQuestionsById(props.id),
        setQuestionsInfo,
        setLoader
      );
    }
  }, []);

  return (
    <div>
      <Modal
        show={props.surveyQuestionModal}
        onHide={() => props.setSurveyQuestionModal(true)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title">{props.actionType === "Add_Data" ? 'Add' : props.actionType === "View_Data" ? "View" : "Edit"} Survey Questions</h5>
        </Modal.Header>
        <Modal.Body ref={modelRef} className="modal-height">
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
            </div>
          )}
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Question
              </label>
              <input
                type="text"
                className="form-control"
                id="question"
                name="question"
                disabled={props.type === "View_Data"}
                onKeyDown={handleenter}
                value={questionsInfo.question
                  .trimStart()
                  .replace(/\s{2,}/g, " ")}
                onChange={handleInputs}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="title" className="form-label">
                Answer Type
              </label>
              <select
                className="form-select"
                value={questionsInfo.answerType}
                onChange={handleInputs}
                disabled={props.type === "View_Data"}
                name="answerType"
                id="answerType"
              >
                <option value="" disabled selected>
                  Choose Option
                </option>
                <option value="TEXT">Text</option>
                <option value="RADIO">Radio Button</option>
                <option value="CHECKBOX">Checkbox</option>
                <option value="DROPDOWN">Dropdown</option>
              </select>
            </div>
            {questionsInfo?.options?.map((curOption, index) => (
              <div
                className={`${
                  questionsInfo.answerType === "TEXT" ? "d-none" : "mb-4"
                }`}
              >
                <label htmlFor="title" className="form-label">
                  Option {index + 1}
                </label>
                <div className="row">
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      className="form-control"
                      id={`optionValue${index}`}
                      name={`optionValue${index}`}
                      disabled={props.type === "View_Data"}
                      onKeyDown={handleenter}
                      value={curOption.trimStart().replace(/\s{2,}/g, " ")}
                      onChange={(e) => {
                        let optionsList = questionsInfo.options;
                        optionsList[index] = e.target.value;
                        setQuestionsInfo({
                          ...questionsInfo,
                          options: optionsList,
                        });
                        removeValidations();
                      }}
                    />
                  </div>
                  <button  className="btn btn-outline-secondary my-2 my-md-0 col-12 col-md-2" onClick={(e)=>handleDeleteOption(index,e)} disabled={questionsInfo?.options?.length===1}>Delete</button>
                </div>
              </div>
            ))}
            {!(
              props.type === "View_Data" || questionsInfo.answerType === "TEXT"
            ) && (
              <button
                onClick={addOptionField}
                className="btn btn-outline-primary mb-4"
              >
                Add option
              </button>
            )}

            <div className=" mb-4">
              <label htmlFor="title" className="form-label">
                Token Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="tokenAmount"
                name="tokenAmount"
                value={questionsInfo.tokenAmount}
                disabled={props.type === "View_Data"}
                onKeyDown={handleenter}
                onChange={(e) => {
                  let tokenValue = e.target.value;
                  validateToken(tokenValue, setQuestionsInfo, questionsInfo);
                  removeValidations();
                }}
              />
            </div>
          </form>
        </Modal.Body>
        {!(props.type === "View_Data") && (
          <Modal.Footer>
            <Button
            variant="primary"
            disabled={loader}
            onClick={formValidation}
          ><div className="d-flex align-center">
            {loader ? 
            <>
            <div className="center"><div className="loader"></div></div>
            </>
            : ""}
            <p className="d-inline mx-2 my-0">
              {props.type === "Add_Data" ? "Add" : "Update"}
            </p>
            </div>
          </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default SurveyQuestionModal;
