import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, SuccessToast,InformationToast } from "../utilities/notification";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import { validateToken } from "../utilities/helpers";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const AddJourneyModal = (props) => {
    const modelRef = useRef();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const PostApiServices = usePostDataServices();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const imageServices = useImageServices();
    const [loader, setLoader] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [validation, setValidation] = useState();
    const [updatedjourneygoal, setupdatedjourneygoal] = useState({});
    const [journeygoaldata, setjourneygoaldata] = useState({
      name: "",
      categoryId: props.categoryId,
      tokenAmount: "",
    });

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setjourneygoaldata({...journeygoaldata, 
          [name]:value
        })
        updateInputs(name, value);
        removeValidations();
      };

    const formValidation = () => {
        setLoader(true)
        setInvalidFields([])
        for (const key of Object.keys(journeygoaldata)) {
          if (journeygoaldata[key] === "") {
            invalidFields.push(key)
                setValidation(false)
                setLoader(false)
            }
          }
        if (validation && !invalidFields.length) {
          handleAddUpdateData();
        }
      };

      const handleAddUpdateData =()=>{
        let data = {
          name : journeygoaldata.name,
          categoryId : journeygoaldata.categoryId,
          tokenAmount : Number(journeygoaldata.tokenAmount)
        }
        if(props.id){
          UpdateApiServices.updateJourneyGoalById(data,props.id).then((res)=>{
            props.setAddJourneyModal(false)
            if(res.status === 200){
              SuccessToast("JourneyGoal Updated Sucessfully")
            }else if (res.status === 401) {
              InformationToast("Session has been expired. Please login again !!!");
              localStorage.clear();
              authContext.setLoggedIn(false)
              navigate("/");
            }else{
              ErrorToast("Something went wrong while Updating data !!!")
            }
          }).catch((err)=>{
            ErrorToast('Something went wrong while Updating data')
          }).finally(() => setLoader(false))
        }else{
          PostApiServices.AddJourneyGoal(data).then((res)=>{
            props.setAddJourneyModal(false)
            if(res.status === 201){
              SuccessToast("JourneyGoal Updated Sucessfully")
            }else if (res.status === 401) {
              InformationToast("Session has been expired. Please login again !!!");
              localStorage.clear();
              authContext.setLoggedIn(false)
              navigate("/");
            }else{
              ErrorToast('Something went wrong while Updating data')
            }
          }).catch((err)=>{
            ErrorToast('Something went wrong while Adding data')
          }).finally(() => setLoader(false))
        }
      }

      
    const updateInputs = (...data) => {
      setupdatedjourneygoal({ ...updatedjourneygoal, [data[0]]: data[1] });
      removeValidations();
    };

    const removeValidations = () => {
        setValidation(true);
        setInvalidFields([]);
      };

    useEffect(()=>{
      if(props.type === "Edit_Data" || props.type === "View_Data"){
        GetApiServices.getJourneyGoalById(props.id).then((res)=>{
          const data = res.data;
          setjourneygoaldata({
            name: data.name,
            categoryId: data.categoryId,
            tokenAmount: data.tokenAmount,
          })
        }).catch((err)=>{
          ErrorToast('Something went wrong while getting data')
        })
        }
    },[])


    return (
        <div>
          <Modal
            show={props.addJourneyModal}
            onHide={() => props.setAddJourneyModal(true)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{props.type === "Add_Data" ? 'Add' : props.type === "Edit_Data" ? "Edit" : "View"} Journey Goal</Modal.Title>
            </Modal.Header>
            <Modal.Body ref={modelRef}>
              {validation === false && (
                <div class="alert alert-danger fw-bold" role="alert">
                  Please fill out all the fields !!!
                    </div>
              )}
              <form>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                    Goal Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    disabled={props.type === 'View_Data'}
                    value={journeygoaldata.name}    
                    onChange={(e)=>{
                      const { value } = e.target;
                      const cleanedValue = value.trimStart().replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s{2,}/g, ' ');
                      setjourneygoaldata({ ...journeygoaldata, name: cleanedValue });
                      removeValidations();
                      updateInputs('name',cleanedValue)
                    }}         
                  />
                </div>
                <div className="mb-4">
                    <label htmlFor="title" className="form-label">
                        Category Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      id="categoryId"
                      name="categoryId"     
                      value={props.categoryName}               
                      onChange={handleInputs}
                    />
                </div>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label">
                    Token Amount
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={props.type === 'View_Data'}
                    id="tokemAmount"
                    name="tokemAmount"
                    min={0}       
                    maxLength={5}      
                    value={journeygoaldata.tokenAmount}             
                    onChange={(e) => {
                      let tokenValue = e.target.value;
                      validateToken(tokenValue, setjourneygoaldata, journeygoaldata, updateInputs);
                      removeValidations();
                    }}
                  />
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {props.type != "View_Data" &&
              <Button
               variant="primary"
                onClick={() => formValidation()}
                disabled={loader}
              >
            <div className="d-flex align-center">
              {loader ? 
              <>
              <div className="center"><div className="loader"></div></div>
              </>
              : ""}
              <p className="d-inline mx-2 my-0">
              {props.type === "Add_Data" ? "Add" : "Update"}
              </p>
              </div>
            </Button>
              }
            </Modal.Footer>
          </Modal>
        </div>
      );
}

export default AddJourneyModal