import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/sidebar_logo.svg"
import axios from "axios";
import { ErrorToast } from "../../utilities/notification";
import { svcconfig } from "../../utilities/api-confige";
import AuthContext from "../../context/AuthProvider";

const Login = () => {
  const loginUrl = `${process.env.REACT_APP_BASE_URL}/auth/web-admin/login`
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loginInfo, setLoginInfo] = useState({
    email : '',
    password : ''
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [loader, setLoader] = useState(false)
  const [validation, setValidation] = useState();
  const [showPassword, setShowPassword] = useState();

  const handleInputs = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value});
    removeValidations();
  };

  const formValidation = (e) => {
    setLoader(true)
    e.preventDefault();
    for (const key of Object.keys(loginInfo)) {
      if (loginInfo[key] === "") {
        if ((key != 'category') && (key != 'status')){
          invalidFields.push(key)
          setValidation(false);
          setLoader(false)
        }
      }
    }
    if (validation && !invalidFields.length) {
      handleLogin();
    }
  };

  const removeValidations = () => {
    setValidation(true);
    setInvalidFields([]);
  };

  const handleLogin = async (e) =>{
    axios.post(`${loginUrl}`,loginInfo,{headers : svcconfig()})
    .then((res)=>{
      if(res.status == 200){
        if(res.data.data[0].user){
          localStorage.setItem('user',JSON.stringify(res.data.data[0].user))
          localStorage.setItem('data',JSON.stringify(res.data.data[0].tokenData.accessToken))
          authContext.setLoggedIn(true);
          // authContext.setUserData(JSON.parse(res.data.data[0].user))
          authContext.setUserData(res.data.data[0].user)
          // navigate("/")
        }
      }
    })
      .catch((err)=>{
        console.log("login failed",err);
        ErrorToast('Something Went Wrong. Please try again !!!')
      })
      .finally(()=>setLoader(false))
  }


  return (
    <>
      <div className="container login-screen mt-5">
        <div className="row mx-0 px-0 justify-content-center align-items-center mt-4">
          <div className="">
            <div className="login-form shadow m-auto">
            <div className="card-header py-2 text-center">
                <img src={Logo} className="py-1"/>
            </div>
              <form className="px-4 pt-5 pb-3">
                {validation === false && (
                  <div class="alert alert-danger fw-bold" role="alert">
                    Please fill out all the fields !!!
                  </div>
                )}
                <div className="mb-4">
                  <label className="form-label mb-1">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    name='email'
                    id="email"
                    value={loginInfo.email}
                    onChange={(e)=>handleInputs(e)}
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label mb-1">Password:</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name = 'password'
                    id="password"
                    value = {loginInfo.password}
                    onChange={(e)=>handleInputs(e)}
                  />
                  <span
                    toggle="#password-field"
                    className={
                      showPassword
                        ? "fa fa-fw fa-eye-slash field-icon toggle-password cursor-pointer"
                        : "fa fa-fw fa-eye field-icon toggle-password cursor-pointer"
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  ></span>
                </div>
                <div className="text-center pt-3">
                  <button type="submit" className="btn btn-primary text-center px-5"
                  onClick={(e)=>formValidation(e)}
                  disabled={loader}
                  >
                    Login
                  </button>
                  <Link to="/" className="d-block mt-3">
                    Forget your password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
