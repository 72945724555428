import React, { useRef, useEffect } from "react";
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import "../../assets/CSS/TextEditor.scss";
import Quill from 'quill';
import 'quill-emoji/dist/quill-emoji.css';
import { EmojiBlot } from 'quill-emoji';
import { QuillEmoji } from 'quill-emoji';

// ReactQuill.Quill.register('modules/emoji', QuillEmoji);

const TextEditor = (props) => {

  // var modules = {
  //   toolbar: [
  //     [{ size: ["small", false, "large", "huge"] }],
  //     ["bold", "italic", "underline", "strike", "blockquote"],
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     ["link"],
  //     [
  //       { list: "ordered" },
  //       { list: "bullet" },
  //       { indent: "-1" },
  //       { indent: "+1" },
  //       { align: [] }
  //     ],
  //     [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
  //     ['emoji']
  //   ],
  //   'emoji-toolbar': true,
  //   'emoji-textarea': false,
  //   'emoji-shortname': true
  // };

  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const toolbar = quill.getModule('toolbar');

      toolbar.addHandler('link', function () {
        const range = quill.getSelection();
        if (range) {
          const tooltip = quill.theme.tooltip;
          tooltip.edit('link');

          setTimeout(() => {
            // Clear the value of the link input box
            const input = tooltip.root.querySelector('input[data-link]');
            if (input) {
              input.value = '';  // Empty the input field
              input.focus(); // Focus the input field to let the user type
            }
          }, 10); // Delay slightly to let Quill render the tooltip
        }
      });
    }
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          { align: [] }
        ],
        [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ['emoji']
      ]
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "align", "size", "emoji"
  ];

  return (
    <div>
      <div>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          modules={modules}
          formats={formats}
          value={props.value}
          readOnly={props.readOnly}
          placeholder="write your content ...."
          onChange={props.onTextEditorChange}
          className={props.actionType === "View_Data" ? "hideoptions" : ""}
        >
        </ReactQuill>
      </div>
    </div>
  );

}

export default TextEditor;