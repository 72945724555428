import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/sidebar_logo.svg";
import toggleExpand from "../../assets/images/toggle-expand.svg";

import dashBoard from "../../assets/images/dashboard_icon.svg";
import dashBoardWhite from "../../assets/images/dashboard_icon_white.svg";
import welcomeIcon from "../../assets/images/welcome_icon.svg";
import welcomeIconWhite from "../../assets/images/welcome_icon_white.svg";
import checklistIcon from "../../assets/images/checklist_icon.svg";
import checklistIconWhite from "../../assets/images/checklist_icon_white.svg";
import questionIcon from "../../assets/images/questions_icon.svg";
import questionIconWhite from "../../assets/images/questions_icon_white.svg";
import surveyIcon from "../../assets/images/surveys_icon.svg";
import surveyIconWhite from "../../assets/images/surveys_icon_white.svg";
import userIcon from "../../assets/images/user_icon.svg";
import userIconWhite from "../../assets/images/user_icon_white.svg";
import assetsIcon from "../../assets/images/assets_icon.svg";
import assetsIconWhite from "../../assets/images/assets_icon_white.svg";
import goalIcon from "../../assets/images/goal_icon.svg";
import goalIconWhite from "../../assets/images/goal_icon_white.svg";
import communityIcon from "../../assets/images/community_icon.svg";
import communityIconWhite from "../../assets/images/community_icon_white.svg";
import newsIcon from "../../assets/images/news_icon.svg";
import newsIconWhite from "../../assets/images/news_icon_white.svg";
import qrIcon from "../../assets/images/qr_icon.svg";
import qrIconWhite from "../../assets/images/qr_icon_white.svg";
import advertiseIcon from "../../assets/images/advertise_icon.svg";
import advertiseIconWhite from "../../assets/images/advertise_icon_white.svg";
import badgesIcon from "../../assets/images/badges_icon.svg";
import badgeIconWhite from "../../assets/images/badge_icon_white.svg";
import tokenIcon from "../../assets/images/token_icon.svg";
import tokenIconWhite from "../../assets/images/token_icon_white.svg";

import categoryIcon from "../../assets/images/category_icon.svg";
import categoryIconWhite from "../../assets/images/category_icon_white.svg";

import notificationIcon from "../../assets/images/notification_icon.svg";
import notificationIconWhite from "../../assets/images/notification_icon_white.svg";
import leftArrow from "../../assets/images/left_arrow.svg";
import walletTipsIcon from "../../assets/images/wallet_tips_icon.svg";
import walletTipsIconWhite from "../../assets/images/wallet_tips_icon_white.svg";

const Sidemenu = (props) => {
  const { setToggleSidebar, toggleSidebar } = props;

  return (
    <>
      <Sidebar width="295px"
        className="sidebar-menu shadow-sm"
        // defaultCollapsed={true}
        collapsed={!toggleSidebar}
        breakPoint="sm"
      >
        <div className="sidebar-header d-flex align-items-center">
          <div className="logo-icon align-items-center d-flex w-100">
            <img src={logo} className="main_logo" />
            <img
              src={leftArrow}
              className="left_arrow"
              onClick={() => setToggleSidebar(!toggleSidebar)}
            />
            <img
              src={toggleExpand}
              className="right_arrow"
              onClick={() => setToggleSidebar(!toggleSidebar)}
            />
          </div>
        </div>
        <div className="blank-space"></div>

        <Menu className={toggleSidebar ? "" : ""}>
            <p className="sidebar-label pt-3 pb-2">ADMIN PANEL</p>

            <NavLink to="/" className="sidebar-links">
                <MenuItem icon={<img src={welcomeIcon} />} className="sidbar-active-link grayIcon">
                    <p>Welcome Screen</p>
                </MenuItem>
                <MenuItem icon={<img src={welcomeIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Welcome Screen</p>
                </MenuItem>
            </NavLink>

            <NavLink to="/category" className="sidebar-links">
                <MenuItem icon={<img src={categoryIcon} />} className="sidbar-active-link grayIcon">
                    <p>Categories</p>
                </MenuItem>
                <MenuItem icon={<img src={categoryIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Categories</p>
                </MenuItem>            
            </NavLink>

            <NavLink to="/onboarding" className="sidebar-links">
                <MenuItem icon={<img src={checklistIcon} />} className="sidbar-active-link grayIcon">
                    <p>Onboarding Screens</p>
                </MenuItem>
                <MenuItem icon={<img src={checklistIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Onboarding Screens</p>
                </MenuItem>            
            </NavLink>

            <NavLink to="/onboarding-questions" className="sidebar-links">
                <MenuItem icon={<img src={questionIcon} />} className="sidbar-active-link grayIcon">
                    <p>Onboarding Questions</p>
                </MenuItem>
                <MenuItem icon={<img src={questionIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Onboarding Questions</p>
                </MenuItem>
            </NavLink>

            <NavLink to="/survey" className="sidebar-links">
                <MenuItem icon={<img src={surveyIcon} />} className="sidbar-active-link grayIcon">
                    <p>Surveys</p>
                </MenuItem>
                <MenuItem icon={<img src={surveyIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Surveys</p>
                </MenuItem>            
            </NavLink>

            <NavLink to="/wallet-tips" className="sidebar-links">
                <MenuItem icon={<img src={walletTipsIcon} />} className="sidbar-active-link grayIcon">
                    <p>Wallet Tips</p>
                </MenuItem>
                <MenuItem icon={<img src={walletTipsIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Wallet Tips</p>
                </MenuItem>
            </NavLink>

            <p className="sidebar-label pt-3 pb-2">USER PROFILE SETTINGS</p>
            <NavLink to="/alluser" className="sidebar-links">
                <MenuItem icon={<img src={userIcon} />} className="sidbar-active-link grayIcon">
                    <p>Users</p>
                </MenuItem>
                <MenuItem icon={<img src={userIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Users</p>
                </MenuItem>                
            </NavLink>

            <NavLink to="/assets" className="sidebar-links">
                <MenuItem icon={<img src={assetsIcon} />} className="sidbar-active-link grayIcon">
                    <p>Asset Box</p>
                </MenuItem>
                <MenuItem icon={<img src={assetsIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Asset Box</p>
                </MenuItem>            
            </NavLink>

            <NavLink to="/journey-goals" className="sidebar-links">
                <MenuItem icon={<img src={goalIcon} />} className="sidbar-active-link grayIcon">
                    <p>Journey Goals</p>
                </MenuItem>
                <MenuItem icon={<img src={goalIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Journey Goals</p>
                </MenuItem>
            </NavLink>

            <NavLink to="/wallet" className="sidebar-links">
                <MenuItem  icon={<img src={tokenIcon} />} className="sidbar-active-link grayIcon">
                    <p>Wallet</p>
                </MenuItem>
                <MenuItem  icon={<img src={tokenIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Wallet</p>
                </MenuItem>
            </NavLink>

            <p className="sidebar-label pt-3 pb-2">COMMUNITY PROFILE SETTINGS</p>
            <NavLink to="/community" className="sidebar-links">
                <MenuItem  icon={<img src={communityIcon} />}  className="sidbar-active-link grayIcon">
                    <p>Community Profile</p>
                </MenuItem>
                <MenuItem  icon={<img src={communityIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Community Profile</p>
                </MenuItem>
            </NavLink>

            <NavLink to="/news-announcement" className="sidebar-links">
                <MenuItem  icon={<img src={newsIcon} />}  className="sidbar-active-link grayIcon">
                    <p>News & Announcements</p>
                </MenuItem>
                <MenuItem  icon={<img src={newsIconWhite} />}  className="sidbar-active-link whiteIcon">
                    <p>News & Announcements</p>
                </MenuItem>
            </NavLink>

            <NavLink to="/notification" className="sidebar-links">                
                <MenuItem icon={<img src={notificationIcon} />} className="sidbar-active-link grayIcon">
                    <p>Notification</p>
                </MenuItem>
                <MenuItem icon={<img src={notificationIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Notification</p>
                </MenuItem>
            </NavLink>
            
            <p className="sidebar-label pt-3 pb-2">REWARD PROGRAMS</p>
            <NavLink to="/qr-code" className="sidebar-links">
                <MenuItem icon={<img src={qrIcon} />} className="sidbar-active-link grayIcon">
                    <p>QR Code Generator</p>
                </MenuItem>
                <MenuItem icon={<img src={qrIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>QR Code Generator</p>
                </MenuItem>
            </NavLink>

            <NavLink to="/geofencing" className="sidebar-links">
                <MenuItem icon={<img src={advertiseIcon} />} className="sidbar-active-link grayIcon">
                    <p>Geofencing & Advertisements</p>
                </MenuItem>
                <MenuItem icon={<img src={advertiseIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Geofencing & Advertisements</p>
                </MenuItem>
            </NavLink>

            <NavLink to="/token-management" className="sidebar-links">
                <MenuItem
                    icon={<img src={tokenIcon} />} className="sidbar-active-link grayIcon">
                    <p>Token Management</p>
                </MenuItem>
                <MenuItem icon={<img src={tokenIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Token Management</p>
                </MenuItem>
            </NavLink>
          
            <NavLink to="/badges" className="sidebar-links">
                <MenuItem icon={<img src={badgesIcon} />} className="sidbar-active-link grayIcon">
                    <p>Badges</p>
                </MenuItem>
                <MenuItem icon={<img src={badgeIconWhite} />} className="sidbar-active-link whiteIcon">
                    <p>Badges</p>
                </MenuItem>
            </NavLink>

        </Menu>
      </Sidebar>
    </>
  );
};

export default Sidemenu;
