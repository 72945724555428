import React, { useEffect, useState } from "react";
import useGetDataServices from "../../hooks/useGetData";
import { fetchData, capitalizeFirstLetterOfWords} from "../../utilities/helpers";
import useDeleteDataServices from "../../hooks/useDeleteDataServices";
import { useNavigate } from "react-router";
import { ErrorToast, SuccessToast } from "../../utilities/notification";
import usePutDataServices from "../../hooks/usePutDataServices";
import Loader from "../common/Loader";
import AddSurvey from "../../popupModals/AddSurvey";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Survey = () => {
    const navigate = useNavigate();
    const GetApiServices = useGetDataServices();
    const UpdateApiServices = usePutDataServices();
    const [survey, setSurvey] = useState([]);
    const [loader, setLoader] = useState(true);
    const [disableToggle, setDisableToggle] = useState({ toggle:false, id:""});
    const [surveyModal, setSurveyModal] = useState({
      visibility: false,
      type: "",
      id:""
    });
    const [loader2, setLoader2] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [publishId, setPublishId] = useState();
    

    const handleAddEditData = (type,id) =>{
      setSurveyModal({
        ...surveyModal,
        visibility: true,
        type,
        id
      });
    }

    const handlePublish = (id) =>{
      setLoader2(true)
      UpdateApiServices.publishSurvey(id)
      .then((res)=>{
        setConfirmModal(false)
        setPublishId()
        setLoader2(false)
        SuccessToast("Survey Published Successfully");      
      })
      .catch((err)=>{
        setLoader2(false)
        ErrorToast('Something Went Wrong while publishing');
      })
      .finally(()=>{
        fetchData(GetApiServices.getAllSurveys(), setSurvey, setLoader);
      })
    };

    const handleStatus =(data)=>{
      setDisableToggle({toggle:true, id:data.id})
      let status = (data.isActive == true) ? false : true ;
      
      UpdateApiServices.updateSurveyStatus(data.id,status)
      .then((res)=>{
        fetchData(GetApiServices.getAllSurveys(), setSurvey, setLoader)
      })
      .catch((err)=>{
        console.error("Error while changin survey status : ",err)
      })
      .finally(()=>setDisableToggle({toggle:false, id:data.id}))

    }

    useEffect(()=>{
      fetchData(GetApiServices.getAllSurveys(), setSurvey, setLoader)
    },[surveyModal])

    return (
        <>
        <div className="container-fluid jumbotron shadow-sm">
          <div className="row col-12 mx-0 px-0 justify-content-between align-items-center my-3">
            <div className="col">
              <h4 className="text-primary fw-bold">Surveys</h4>
            </div>
            <div className="col-auto">              
                <button
                    className="btn btn-primary add-button col-auto"
                    onClick={() => handleAddEditData("Add_Data")}                    
                >
                    Add Survey
                </button>

            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-12 table-responsive fixed-table"  style={{ overflowX: "auto" }}>
              <table class="table table-fit  align-middle">
                <thead className="table-light sticky-position table-header-height">
                  <tr className=" table-header-font align-middle">
                    <th scope="col" className="text-center">Name</th>
                    <th scope="col" className="text-center">Audience</th>
                    <th scope="col" className="text-center col">Badge</th>
                    <th scope="col" className="text-center col">Start to End Date</th>
                    <th scope="col" className="text-center">Status</th>
                    <th scope="col" className="text-center">Publish</th>
                    <th scope="col" className="text-center"></th>
                  </tr>
                </thead>
                <tbody className="">{
                  loader ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <Loader />
                      </td>
                    </tr>
                  ) :
                   ( survey.length ?(
                      survey.map((currSurvey, id) => (
                    <tr key={id}>
                        <td className="col-1 text-center">{currSurvey?.name}</td>
                        <td className="col-2 text-center">
                        {
                        (currSurvey?.audienceType === "SELECT_COMMUNITY" && 
                        currSurvey?.communities?.length > 0) ? 
                        (
                                  <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                      <Tooltip id={`tooltip-left`}>{
                                          currSurvey?.communities.map((community) => 
                                          <div key={community.name}>{community.name},</div>
                                          )
                                        } </Tooltip>
                                }
                              >
                              <p>{currSurvey?.communities[0].name},...</p>
                              </OverlayTrigger> 
                          )
                          : currSurvey?.audienceType === "ALL_USERS" ? "All Users" : capitalizeFirstLetterOfWords(currSurvey?.audienceType)
                        }
                        </td>
                        <td className="text-center">
                            <a href="#">
                              {currSurvey?.badge?.imageUrl && <img width="70" height="70" src={currSurvey?.badge?.imageUrl}/>}
                            </a>
                        </td>
                        <td className="text-center col-3">
                            {currSurvey.scheduleType === "INDEFINITE" ? "INDEFINITE" : 
                             `${currSurvey.startDate?.split('T')[0].replaceAll('-','/')} to  ${currSurvey?.endDate?.split('T')[0].replaceAll('-','/')}`  
                             }
                        </td>
                        <td className="text-center">
                            <div className="form-switch">
                                <input className="form-check-input custom-control-input" type="checkbox" role="switch" 
                                checked={currSurvey.isActive} 
                                onChange={() => handleStatus(currSurvey)} 
                                disabled={!currSurvey?.isPublish || (disableToggle?.toggle && disableToggle?.id == currSurvey?.id)}/>
                            </div>
                        </td>
                        <td className="text-center">
                          <div className="form-switch text-center">
                            <input className="form-check-input" type="checkbox" role="switch" disabled={currSurvey.isPublish}
                            checked={currSurvey?.isPublish}
                            onClick={() => {setConfirmModal(true); setPublishId(currSurvey?.id);}}
                           />
                          </div>
                        </td>
                        <td className="text-center">
                        <div className="btn-group">
                        <button type="button" className="dot" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul className="dropdown-menu py-0" >
                            {currSurvey.isPublish ?
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("View_Data", `${currSurvey.id}`) } >
                              <i className="fa-solid fa-eye"></i> View
                              </li>
                            </div>
                            :(
                              <>
                            <div className="">
                              <li className="dropdown-item" onClick={() => handleAddEditData("Edit_Data", `${currSurvey.id}`) } >
                              <i className="fa-solid fa-pen-to-square"></i> Edit
                              </li>
                            </div>
                            </>
                            )
                            }
                            
                            <div className="">
                              <li className="dropdown-item" onClick={() => navigate(`/survey/questions/${currSurvey.id}`) } >
                              <i className="fa-solid fa-upload"></i> Add Survey question
                              </li>
                              </div>
                              <div className="">
                              <li className="dropdown-item" onClick={() => navigate(`/survey/result/${currSurvey.id}`)} >
                              <i className="fa-solid fa-pen-to-square"></i> View Response 
                              </li>
                            </div>
                            </ul>
                        </div>
                        </td>
                    </tr>
                   )) 
                   ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <h3>No Data Available</h3>
                      </td>
                    </tr>
                   )
                  ) 
                  }              
                </tbody>
              </table>
            </div>
          </div>
          {surveyModal.visibility ? (
            <AddSurvey
              type={surveyModal.type}
              id={surveyModal.id}
              surveyModal={surveyModal.visibility}
              setSurveyModal={setSurveyModal}
            />
          ) : (
            ""
          )}
          {/* {deleteModal.visibility ? (
            <ConfirmationModal
              deleteItem={deleteModal.deleteItem}
              deleteModal={deleteModal.visibility}
              setDeleteModal={setDeleteModal}
              setLoader={setLoader}
              id={deleteModal.id}
              deleteDataApi={() => DeleteApiServices.deleteOnboardingScreen(deleteModal.id)}
              // setNewData={setUserList}
              // fetchNewDataApi={() => GetApiServices.getAlluserlist()}
            />
          ) : (
            ""
          )} */}
        </div>

          {confirmModal && 
            <div>
              <Modal
                show={confirmModal}
                onHide={ () => {setConfirmModal(false); setPublishId();}}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                <h5 className="modal-title">Confirmation</h5>
                </Modal.Header>
                <Modal.Body>
                  <h5>
                    Are you sure you want to publish{" "}?
                  </h5>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={ () => {setConfirmModal(false); setPublishId();}}
                    >
                    <div className="d-flex align-center">
                    <p className="d-inline mx-2 my-0">
                    Cancel
                    </p>
                    </div>
                  </Button>
                  <Button
                  variant="secondary"
                  onClick={() => handlePublish(publishId)}
                  >
                  <div className="d-flex align-center">
                  {loader2 ? 
                  <>
                  <div className="center"><div className="loader"></div></div>
                  </>
                  : ""}
                  <p className="d-inline mx-2 my-0">
                  Okay
                  </p>
                  </div>
                </Button>
                </Modal.Footer>
              </Modal>
            </div>
          }
        </>
      );
}

export default Survey