// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quill .ql-editor {
  font-family: "Montserrat";
}

.hideoptions .ql-toolbar .ql-size,
.hideoptions .ql-toolbar .ql-align,
.hideoptions .ql-toolbar .ql-color,
.hideoptions .ql-toolbar .ql-formats:first-child {
  display: none !important;
}

.hideoptions .ql-toolbar {
  background-color: var(--bs-secondary-bg) !important;
  pointer-events: none !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/TextEditor.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AACA;;;;EAIE,wBAAA;AAEF;;AACA;EACE,mDAAA;EACA,+BAAA;AAEF","sourcesContent":[".quill .ql-editor {\n  font-family: \"Montserrat\";\n}\n.hideoptions .ql-toolbar .ql-size,\n.hideoptions .ql-toolbar .ql-align,\n.hideoptions .ql-toolbar .ql-color,\n.hideoptions .ql-toolbar .ql-formats:first-child {\n  display: none !important;\n}\n\n.hideoptions .ql-toolbar{\n  background-color: var(--bs-secondary-bg) !important;\n  pointer-events: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
