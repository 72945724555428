import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ErrorToast, InformationToast, SuccessToast } from "../utilities/notification";
import { fetchData } from "../utilities/helpers";
import usePostDataServices from "../hooks/usePostData";
import useImageServices from "../hooks/useImageServices";
import usePutDataServices from "../hooks/usePutDataServices";
import Loader from "../components/common/Loader";
import useGetDataServices from "../hooks/useGetData";
import AuthContext from "../context/AuthProvider";
import { useContext } from "react";
import { useNavigate } from "react-router";

const AddWalletAdvertiseModal = (props) => {
  const modelRef = useRef();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const PostApiServices = usePostDataServices();
  const GetApiServices = useGetDataServices();
  const UpdateApiServices = usePutDataServices();
  const imageServices = useImageServices();
  const [updatedAdvertiseData, setupdatedAdvertiseData]=useState({})
  const [advertisementData, setAdvertiesData] = useState({
    title: "",
    description: "",
    subDescription: "",
    imageUrl: "",
    url: "",
  });
  const [loader, setLoader] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [validation, setValidation] = useState();

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setAdvertiesData({...advertisementData,
      [name] : value.trimStart().replace(/\s{2,}/g, ' ')
    })
    updateInputs(name,value)
    removeValidations();
  };

  const formValidation = () =>{
    setLoader(true)
    setInvalidFields([])

    for(const key of Object.keys(advertisementData)){
      if(advertisementData[key] === ""){
        invalidFields.push(key)
        setLoader(false);
        setValidation(false);
        modelRef.current.scrollTop = 0;
      }
    }

    if(validation && !invalidFields.length){
      handleAddEditData();
    }
  }
  
  const handleAddEditData = () =>{
    let walletdata = {
      title : advertisementData.title,
      description : advertisementData.description,
      subDescription : advertisementData.subDescription,
      imageUrl : advertisementData.imageUrl,
      url : advertisementData.url,
      advertisementTypeId : props.advertisementTypeId
    }
    if(props.id){
      UpdateApiServices.updateAdvertisementById(walletdata,props.id).then((res)=>{
        props.setAddWalletAdvertiseModal(false)
        if(res.status == 200){
          SuccessToast("Wallet Advertisement Updated Successfully")
        }else if (res.status === 401) {
          InformationToast("Session has been expired. Please login again !!!");
          localStorage.clear();
          authContext.setLoggedIn(false)
          navigate("/");
        }else{
          ErrorToast("Something went wrong while creating QR Code !!!");
        }
      })
      .catch((err)=>{
        ErrorToast("Something went wrong while updating data", err)
      })
      .finally(()=>setLoader(false))
    }
    else{
      PostApiServices.addAdvertisement(walletdata).then((res)=>{
        props.setAddWalletAdvertiseModal(false)
        if(res.status == 201){
          SuccessToast("Wallet Advertisement Added Successfully");
        }else if (res.status === 401) {
          InformationToast("Session has been expired. Please login again !!!");
          localStorage.clear();
          authContext.setLoggedIn(false)
          navigate("/");
        }else{ErrorToast("Something went wrong while creating QR Code !!!");}
      })
      .catch((err)=>{
        ErrorToast("Something went wrong while adding data", err)
      })
      .finally(()=>setLoader(false))
    }
  }

  const handleImageUpload = (file) => {
    setLoader(true);
    if (file?.type.startsWith("image/")) {
      PostApiServices.uploadImgToServer({ file: file })
        .then(async (res) =>{
          setAdvertiesData({ ...advertisementData, imageUrl: res.data.Location })
          updateInputs("imageUrl", res.data.Location)
        })
        .catch((err) => ErrorToast("Problem while uploading image"))
        .finally(() => setLoader(false));
    } else {
      file && InformationToast("Please choose only image file to be uploaded.");
      setAdvertiesData({ ...advertisementData, imageUrl: ""})
      setLoader(false);
    }
  };

  const updateInputs = (...data) => {
    setupdatedAdvertiseData({ ...updatedAdvertiseData, [data[0]]: data[1] });
    removeValidations();
  };

  const removeValidations = () => {
      setValidation(true);
      setInvalidFields([]);
    };

  useEffect(() => {
    if (props.id) {
      fetchData(
        GetApiServices.getAdvertisementsById(props.id),
        setAdvertiesData,
        setLoader
      );
    }
  }, []);

  return (
    <div>
      <Modal
        show={props.addWalletAdvertiseModal}
        onHide={() => props.setAddWalletAdvertiseModal(true)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <h5 className="modal-title">{props.type === "Edit_Data" ? "Edit" : props.type === "View_Data" ? "View" : "Add"} Advertise</h5>

        </Modal.Header>
        <Modal.Body ref={modelRef} className="modal-height">
          {validation === false && (
            <div class="alert alert-danger fw-bold" role="alert">
              Please fill out all the fields !!!
            </div>
          )}
          <form>
            <div className="mb-3">
              <label htmlFor="title" className="form-label mb-1">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={advertisementData.title}
                disabled={props.type === "View_Data"}
                onChange={(e)=>{
                  const { value } = e.target;
                  const cleanedValue = value.trimStart().replace(/[^a-zA-Z\s]/g, '').replace(/\s{2,}/g, ' ');
                  setAdvertiesData({ ...advertisementData, title: cleanedValue });
                  removeValidations();
                  updateInputs('title',cleanedValue)
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label mb-1">
                Description
              </label>
              <textarea
                className="form-control"
                name="description"
                value={advertisementData.description}
                disabled={props.type === "View_Data"}
                onChange={handleInputs}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label mb-1">
                Sub-Description
              </label>
              <textarea
                className="form-control"
                name="subDescription"
                value={advertisementData.subDescription}
                disabled={props.type === "View_Data"}
                onChange={handleInputs}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label mb-1">
                Logo
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                id="title"
                name="imageUrl"
                disabled={props.type === "View_Data"}
                onChange={(e) => handleImageUpload(e.target.files[0])}
              />
              {advertisementData.imageUrl ? (
                <div>
                  <p>Image Preview : </p>
                  <div className="row ms-2">
                    {loader ? (
                      <div className="h-75 col-5 text-center">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <img
                          src={advertisementData.imageUrl}
                          className=" col-5 img-fluid border"
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label mb-1">
                URL
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="url"
                value={advertisementData.url}
                disabled={props.type === "View_Data"}
                onChange={handleInputs}
              />
            </div>
          </form>
        </Modal.Body>
        {!(props.type === "View_Data") && (
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={loader}
              onClick={formValidation}
            >
              {loader ? 
              <>
              <div className="center"><div className="loader"></div></div>
              </>
              : ""}
              <p className="d-inline mx-2">
                {props.type === "Add_Data" ? "Add" : "Update"}
              </p>
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default AddWalletAdvertiseModal;
